import { errorPrepend } from '@/models';
import axios from 'axios';
import toFormData from '@/models/FormData';
import i18n from '@/i18n';

export class Superlearning {
  static DELETE_ERROR = i18n.global.t('messages.no del audio') + errorPrepend();
  static CREATE_ERROR = i18n.global.t('messages.no upload audio') + errorPrepend();
  static LOAD_MULTIPLE_ERROR = i18n.global.t('messages.no load audio') + errorPrepend();

  constructor (
    public id: number,
    public name: string,
    public audioUrl: string) {
  }

  static async delete (audioId: number): Promise<void> {
    const { data } = await axios.delete(`api/superlearning/${audioId}`);
    return data;
  }

  static async getAll (): Promise<Superlearning[]> {
    const { data } = await axios.get('api/superlearning/all');
    return data;
  }

  static async create (audio: {name: string, audio: File}): Promise<{ id: number }> {
    const { data } = await axios.post('api/superlearning', toFormData(audio));
    return data;
  }

  static async getById (audioId: number): Promise<Superlearning> {
    const { data } = await axios.get(`api/superlearning/${audioId}`);
    return data;
  }
}
