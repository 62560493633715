import { PublicClientApplication } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: '36c711e8-e005-4e91-85fd-15af22d5bc44',
    authority: 'https://login.microsoftonline.com/34a01eb2-642b-46c7-b168-bdcc46c65ab2',
    redirectUri: `${location.origin}/login/`,
    postLogoutRedirectUri: `${location.origin}/login/`
  },
  cache: {
    cacheLocation: 'localStorage'
  }
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const loginRequest = {
  scopes: ['User.Read']
};

export const logoutRequest = {
  account: msalInstance.getActiveAccount()
};

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
  graphUsersEndpoint: 'https://graph.microsoft.com/v1.0/users',
  graphGroupsEndpoint: 'https://graph.microsoft.com/v1.0/groups'
};
