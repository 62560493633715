import i18n from '@/i18n';

export function errorPrepend ():string { return i18n.global.t('messages.try again'); }
export * from './Book';
export * from './Course';
export * from './User';
export * from './Language';
export * from './Lesson';
export * from './Translation';
export * from './Study';
export * from './School';
export * from './SideNavItem';
export { CommonError } from './CommonError';
export * from './Settings';
export * from './Teacher';
export * from './Student';
export * from './Class';
export * from './Organizer';
export * from './Superlearning';

const devMode = process.env.NODE_ENV === 'development';
export const backendUrl = devMode
  ? 'http://localhost:80'
  : 'https://wordly.projects2020.sz-ybbs.ac.at';
