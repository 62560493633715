import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/noLogin/Home.vue';
import store from '@/store';
import { registerGuard } from '@/router/Guard';
import { UserType } from '@/models';
import i18n from '@/i18n';

function switchType (student?: () => void, teacher?: () => void, admin?: () => void, organizer?: () => void) {
  switch (store.state.user.type) {
    case 'student':
      return student ? student() : null;
    case 'teacher':
      return teacher ? teacher() : null;
    case 'administrator':
      return admin ? admin() : null;
    case 'organizer':
      return organizer ? organizer() : null;
  }
}

declare module 'vue-router' {
  interface RouteMeta {
    disableSideNav?: boolean
    requiresAuth?: boolean
    users?: UserType[]
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Startseite',
    component: Home
  },
  {
    path: '/dashboard/',
    name: 'Kursübersicht',
    meta: { requiresAuth: true, users: ['student', 'teacher'] },
    component: () => switchType(
      () => import('../views/student/CourseOverview.vue'),
      () => import('../views/teacher/CourseOverview.vue')
    )
  },
  {
    path: '/settings/',
    name: 'Einstellungen',
    meta: { requiresAuth: true, users: ['student', 'teacher', 'administrator', 'organizer'] },
    component: () => import('../views/common/Settings.vue')
  },
  {
    path: '/course/:id(\\d+)/',
    name: 'Kursdetails',
    meta: { requiresAuth: true, users: ['student', 'teacher'] },
    component: () => switchType(
      () => import('../views/student/CourseDetails.vue'),
      () => import('../views/teacher/CourseDetails.vue')
    )
  },
  {
    path: '/lesson/:id(\\d+)/',
    name: 'Lektionsdetails',
    meta: { requiresAuth: true, users: ['student', 'teacher', 'administrator'] },
    component: () => switchType(
      () => import('../views/student/LessonDetails.vue'),
      () => import('../views/teacher/LessonDetails.vue'),
      () => import('../views/teacher/LessonDetails.vue')
    )
  },
  {
    path: '/study/',
    name: 'Lernen',
    meta: { requiresAuth: true, users: ['student'] },
    component: () => import('../views/student/Study.vue')
  },
  {
    path: '/books/',
    name: 'Bücherverwaltung',
    meta: { requiresAuth: true, users: ['administrator'] },
    component: () => import('../views/admin/Books.vue')
  },
  {
    path: '/book/:id(\\d+)/',
    name: 'Buchdetails',
    meta: { requiresAuth: true, users: ['administrator'] },
    component: () => import('../views/admin/BookDetails.vue')
  },
  {
    path: '/book/:id(new)/',
    name: 'BuchDetails',
    meta: { requiresAuth: true, users: ['administrator'] },
    component: () => import('../views/admin/BookDetails.vue')
  },
  {
    path: '/users/',
    name: 'Benutzerverwaltung',
    meta: { requiresAuth: true, users: ['organizer'] },
    component: () => import('../views/organizer/UserManagement.vue')
  },
  {
    path: '/schools/',
    name: 'Schulverwaltung',
    meta: { requiresAuth: true, users: ['administrator'] },
    component: () => import('../views/admin/SchoolManagement.vue')
  },
  {
    path: '/superlearning/',
    name: 'Audioverwaltung',
    meta: { requiresAuth: true, users: ['administrator'] },
    component: () => import('../views/admin/AudioManagement.vue')
  },
  {
    path: '/classes/',
    name: 'Klassenverwaltung',
    meta: { requiresAuth: true, users: ['organizer'] },
    component: () => import('../views/organizer/ClassManagement.vue')
  },
  {
    path: '/login/',
    name: 'Anmeldung',
    meta: { disableSideNav: true },
    component: () => import('../views/noLogin/Login.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Seite nicht gefunden',
    meta: { disableSideNav: true },
    component: () => import('../views/noLogin/404.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

registerGuard(router);
export default router;
