import Swal from 'sweetalert2';

export const customClass = {
  confirmButton: 'btn btn-primary',
  cancelButton: 'btn btn-danger',
  denyButton: 'btn btn-danger',
  title: 'text-dark',
  input: ['border-0', 'form-control', 'shadow'],
  htmlContainer: ['overflow-visible']
};

export default Swal.mixin({
  customClass,
  buttonsStyling: false
});
