export default {
  "messages": {
    "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка 404"])},
    "Anmeldung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["реєстрація"])},
    "SZ Wordly registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реєстрація SZ-Worldly"])},
    "Welcome back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["З поверненням"])},
    "or with local user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["або з локальним користувачем"])},
    "SZ-Wordly - vocabulary trainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SZ-Wordly - словниковий тренажер"])},
    "save email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запам'ятати електронну адресу"])},
    "E-mail / username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електронна пошта / ім'я користувача"])},
    "Sign-Out from SZ Wordly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вихід із SZ Worldly"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успіх!"])},
    "Successfully logged off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успішно вийшли!"])},
    "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["добре"])},
    "Sign in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увійти"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
    "Administrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адміністратор"])},
    "Delete?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити?"])},
    "Delete book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чи справді книгу потрібно видалити?"])},
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["меню"])},
    "source language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мова оригіналу"])},
    "target language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мова перекладу"])},
    "Publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видавець"])},
    "Startseite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Домашня сторінка"])},
    "book templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["шаблони книг"])},
    "school administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["адміністрація школи"])},
    "audio management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["управління звуком"])},
    "Einstellungen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування"])},
    "books management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["управління книгами"])},
    "Available Books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступні книги"])},
    "Create new book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити нову книгу"])},
    "Surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прізвище"])},
    "Extinguish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити"])},
    "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["контакт"])},
    "Change Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити пароль"])},
    "old password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старий пароль"])},
    "New Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новий пароль"])},
    "Repeat new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторіть новий пароль"])},
    "password length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Довжина пароля"])},
    "Show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показати"])},
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити"])},
    "first name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я"])},
    "e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електронна пошта"])},
    "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дії"])},
    "Create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити"])},
    "search term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошуковий термін"])},
    "Create new school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити нову школу"])},
    "Create school button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кнопка створення школи"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса"])},
    "create school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["створити школу"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
    "Add new superlearning music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати нову супернавчальну музику"])},
    "music name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва музики"])},
    "name of the new music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["назва нової музики"])},
    "Add to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати до"])},
    "Existing learning music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Існуюче навчання муз"])},
    "Access failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка доступу"])},
    "mistake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка!"])},
    "You do not have access to this resource!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви не маєте доступу до цього ресурсу!"])},
    "Sign out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вийти з аккаунта"])},
    "Schüler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["студент"])},
    "your profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Твій профіль"])},
    "E-mail (username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електронна пошта (ім'я користувача"])},
    "voice output": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["голосовий вихід"])},
    "superlearning music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["супернавчальна музика"])},
    "Voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Голос"])},
    "masculine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["чоловічого роду"])},
    "Feminine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жіночий"])},
    "Number of vocabulary per learning unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість словникового запасу на навчальний блок"])},
    "my courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мої курси"])},
    "There are no courses available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає доступних курсів"])},
    "Sign out of school account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вийти зі шкільного облікового запису"])},
    "start training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почніть навчання"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["опис"])},
    "Select vocabulary:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть словниковий запас"])},
    "three choices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть правильний переклад слова з трьох запропонованих варіантів."])},
    "Form vocabulary pairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Утворіть словникові пари, натиснувши мову оригіналу та мову перекладу слова."])},
    "training completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Навчання завершено!"])},
    "You have completed x of x": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви виконали x з x вправ правильно без помилок..."])},
    "Repeat Lecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторити лекцію"])},
    "Back to the lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад до уроку"])},
    "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прогрес"])},
    "No picture available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає доступного зображення"])},
    "form vocabulary pairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["утворити словникові пари"])},
    "Choose word based image displayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть правильне слово на основі зображеного зображення та звуку, який ви чуєте. Крім того, спробуйте повторити почуте слово."])},
    "See, hear & select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перегляньте, почуйте та виберіть словниковий запас"])},
    "Write the word that can be seen in the picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напишіть у відповідне текстове поле слово, яке можна побачити на малюнку та почути на аудіо. Крім того, спробуйте повторити почуте слово."])},
    "License:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ліцензія"])},
    "Choose vocabulary based on image displayed.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть правильний словниковий запас на основі зображеного зображення."])},
    "See picture & select vocabulary:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перегляньте малюнок і виберіть словниковий запас"])},
    "Check answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевірте відповіді"])},
    "write vocabulary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["писати словниковий запас"])},
    "Write translation text box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Впишіть правильний переклад слова у запропоноване текстове поле."])},
    "Which units learn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Які одиниці ви б хотіли вивчити?"])},
    "Lehrer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вчитель"])},
    "Create course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити курс"])},
    "course name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва курсу"])},
    "Description:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["опис"])},
    "Source language:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мова оригіналу"])},
    "Add Lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати урок"])},
    "Create Lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити урок"])},
    "Vocabulary list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Словниковий список*"])},
    "*optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* необов'язковий"])},
    "Apply template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Застосувати шаблон"])},
    "insert lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вставити уроки"])},
    "lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уроки"])},
    "course members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учасники курсу"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статистика"])},
    "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблони"])},
    "No course members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає учасників курсу"])},
    "Choose a file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть файл"])},
    "Upload vocabulary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажте словниковий запас"])},
    "Delete Courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити курс(и)"])},
    "Which courses delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Які курси ви хочете видалити?"])},
    "confirm selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["підтвердити вибір"])},
    "delete lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["видалити уроки"])},
    "Which lessons delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Які уроки ви хочете видалити?"])},
    "select class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть клас"])},
    "select students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть учнів"])},
    "Add student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати студента"])},
    "course member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["учасник курсу"])},
    "Organisator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Організатор"])},
    "change class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити клас"])},
    "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["студентів"])},
    "New name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нова назва"])},
    "change name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["змінити назву"])},
    "NoLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NoLogin"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Команда"])},
    "Common": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поширений"])},
    "add a picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додайте картинку"])},
    "login with school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увійдіть зі шкільним обліковим записом"])},
    "page not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цю сторінку не вдалося знайти"])},
    "back to home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повернутися на головну сторінку"])},
    "back to login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад до входу"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["від"])},
    "no learn music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш браузер не підтримує вивчення музики"])},
    "delete music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити музику"])},
    "new class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити новий клас"])},
    "delete class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити клас"])},
    "name of new class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва нового класу"])},
    "remove student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити студента"])},
    "no students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У цьому класі немає учнів"])},
    "show warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показати попередження"])},
    "export new passwords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Експорт нових паролів"])},
    "migrate user ad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перенесіть користувачів і групи з Microsoft Active Directory"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["старт"])},
    "training mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["тренувальний режим"])},
    "no vocab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає доступного словникового запасу"])},
    "enable editing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увімкнути редагування"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["зберегти"])},
    "discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відкинути"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я"])},
    "no book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає доступних книг"])},
    "save changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти зміни"])},
    "csv files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл(и) CSV"])},
    "name of lecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва лекції"])},
    "generate new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Згенеруйте новий пароль"])},
    "reset values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скинути значення"])},
    "delete user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити користувача"])},
    "no desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цей курс не має опису"])},
    "del lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити урок"])},
    "hide lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приховати урок"])},
    "show lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показати урок"])},
    "edit lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати урок"])},
    "no lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає доступних уроків!"])},
    "discard changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати зміни"])},
    "pic desc and license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис зображення/Ліцензія"])},
    "enter license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть ліцензію в поле"])},
    "add student to class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати студента до класу"])},
    "Class of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клас"])},
    "class name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва класу"])},
    "See, hear & write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дивіться, чуйте та запишіть словниковий запас"])},
    "See pic & write vocab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розгляньте малюнок і запишіть словник"])},
    "write word from picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напишіть слово, яке ви бачите на екрані, у відповідному текстовому полі."])},
    "check answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевірте відповідь"])},
    "diabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вимкнено"])},
    "save settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти налаштування"])},
    "no students course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У цьому курсі немає студентів!"])},
    "no statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для цього курсу немає статистики!"])},
    "no templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає шаблонів для цієї мовної комбінації!"])},
    "change course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити курс"])},
    "del user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити користувача"])},
    "add member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати учасника"])},
    "add members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додайте учасників"])},
    "add whole class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цілий клас можна додати, не вибираючи студента!"])},
    "information1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Метою цієї дипломної роботи є розробка платформи онлайн-навчання, яка дозволить студентам навчатися ефективніше, зберігаючи при цьому організаційні зусилля для викладачів якомога меншими. Найбільш традиційним способом вивчення лексики є використання словника. Цей метод не вимагає зусиль для вчителя, але навчання є дуже виснажливим і неефективним."])},
    "information2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найбільшою перевагою онлайн-тренажера в порівнянні зі словниковим запасом є спосіб його вивчення. Платформа дозволяє використовувати широкий спектр методів навчання, враховуючи різні типи учнів. Учні можуть вивчати лексику зі своїх підручників у будь-який час, тому що вчитель надає учням словниковий запас у попередньо складених списках і може постійно контролювати прогрес навчання учнів."])},
    "information3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За допомогою алгоритму навчання, який у фоновому режимі обчислює, який словниковий запас вибрано, можливе індивідуальне навчання, яке спеціально адаптоване до користувача. Алгоритм розставляє першочергову лексику, яку користувач ще не опанував настільки добре, і відповідно частіше перевіряє її."])},
    "information4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слухові учні можуть отримати значну користь від прослуховування музики під час навчання. Мозок навчається набагато ефективніше, якщо під час навчання його стимулюють шумом, але це стосується лише рівномірного та збалансованого фонового шуму. Це створює робочу атмосферу, яка сприяє концентрації, не відволікаючи. Ось чому SZ-Wordly також пропонує супернавчальну музику. Передбачено чотири доріжки"])},
    "our idea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наша ідея"])},
    "no superlearning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає доступної музики для супернавчання."])},
    "no cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без покриття"])},
    "book name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва книги"])},
    "publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва видавництва"])},
    "add csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додайте файл CSV"])},
    "Benutzerverwaltung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Керування користувачами"])},
    "Klassenverwaltung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Керівництво класом"])},
    "Kursübersicht": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Огляд курсу"])},
    "Meine Kurse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мої курси"])},
    "Büchervorlagen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблони книг"])},
    "Schulverwaltung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управління школою"])},
    "Audioverwaltung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Керування звуком"])},
    "Bücherverwaltung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управління книгами"])},
    "Kursdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Деталі курсу"])},
    "Lektionsdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Деталі лекції"])},
    "Lernen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["навчання"])},
    "Buchdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Деталі книги"])},
    "Projektleiter, Frontend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Менеджер проекту, Frontend"])},
    "Frontend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інтерфейс"])},
    "Backend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend"])},
    "music1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інструментальне бароко - Трек"])},
    "music2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інструментальна класика - Трек"])},
    "music3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ембіент - трек"])},
    "music4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Звуки природи / Навколишній шум - Трек"])},
    "del music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви справді хочете видалити музику?"])},
    "no return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цю дію неможливо скасувати!"])},
    "no changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Паролі не змінювалися!"])},
    "edit school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати школу"])},
    "del student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити студента з класу"])},
    "del school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви справді хочете видалити цю школу?"])},
    "school info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шкільна інформація"])},
    "you have": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ти маєш"])},
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["з"])},
    "exercises solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вправи вирішені без помилок..."])},
    "wrong translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На жаль, це неправильно, правильний переклад"])},
    "is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["є"])},
    "password matching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новий пароль має збігатися!"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інформація!"])},
    "confirm del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити"])},
    "session timeout text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сеанс минув. Ви повинні увійти знову!"])},
    "session timeout title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сеанс минув"])},
    "not log in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви не ввійшли в систему"])},
    "log in missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає входу"])},
    "Seite nicht gefunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сторінку не знайдено"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["і"])},
    "no book change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Книгу не можна було змінити."])},
    "no book del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Книгу не вдалося видалити."])},
    "no book create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося створити книгу."])},
    "no book del full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Книги не вдалося повністю видалити."])},
    "no book load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося завантажити книгу."])},
    "no books load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося завантажити книги."])},
    "no class add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося додати клас."])},
    "no classes add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося додати класи."])},
    "no class load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося завантажити клас."])},
    "no classes load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося завантажити класи."])},
    "error class load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Під час видалення вибраного класу сталася помилка."])},
    "error classes load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Під час видалення вибраних класів сталася помилка."])},
    "no class change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клас не можна було змінити."])},
    "fill all fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будь ласка, заповніть усі поля!"])},
    "no data load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося завантажити дані."])},
    "file to big": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажені файли завеликі! Максимальний розмір 40 MiB!"])},
    "no course load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося завантажити курс."])},
    "no course add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося додати курс."])},
    "course error del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Під час видалення вибраних курсів сталася помилка."])},
    "course error change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сталася помилка зміни курсу."])},
    "try again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будь ласка спробуйте ще раз!"])},
    "no lesson add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося додати урок."])},
    "no lessons load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося завантажити уроки."])},
    "no lesson load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося завантажити урок."])},
    "error del lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Під час видалення вибраних уроків сталася помилка."])},
    "no lesson change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Урок не можна було змінити."])},
    "no statistic load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося завантажити статистику."])},
    "template add course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До курсу додано шаблон"])},
    "error add template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка додавання шаблону."])},
    "no organizer add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося додати організатора."])},
    "no organizers add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося додати організаторів."])},
    "no organizer load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося завантажити органайзер."])},
    "no organizers load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося завантажити органайзери."])},
    "error del organizer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Під час видалення вибраного організатора сталася помилка."])},
    "error del organizers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Під час видалення вибраних організаторів сталася помилка."])},
    "no organizer change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Організатора змінити не вдалося."])},
    "no organizers change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Організаторів змінити не вдалося."])},
    "no school add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося додати школу."])},
    "no school load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося завантажити школу."])},
    "no schools load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося завантажити школи."])},
    "error del school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Під час видалення вибраної школи сталася помилка."])},
    "error school change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Школу не можна було змінити."])},
    "request a minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цей запит може тривати до хвилини, наберіться терпіння!"])},
    "settings saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування збережено."])},
    "error save settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка збереження налаштувань."])},
    "error load settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка завантаження налаштувань."])},
    "error load statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося завантажити статистику."])},
    "no student add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося додати студента."])},
    "no students add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося додати студентів."])},
    "no student load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося завантажити студента."])},
    "no students load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося завантажити учнів."])},
    "error del student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Під час видалення вибраного студента сталася помилка."])},
    "error del students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Під час видалення вибраних студентів сталася помилка."])},
    "no student change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учня не можна було змінити."])},
    "no students class change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клас учня змінити не вдалося."])},
    "no students change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учнів не можна було змінити."])},
    "no start training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося запустити режим навчання."])},
    "no del audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося видалити аудіо."])},
    "no upload audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося завантажити аудіо."])},
    "no load audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося завантажити аудіофайли."])},
    "no teacher add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося додати вчителя."])},
    "no teachers add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося додати вчителів."])},
    "no teacher load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося завантажити вчителя."])},
    "no teachers load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося завантажити вчителів."])},
    "error del teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Під час видалення вибраного викладача сталася помилка."])},
    "error del teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Під час видалення вибраних викладачів сталася помилка."])},
    "no change teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вчителя не можна було змінити."])},
    "no change teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вчителя не можна було змінити."])},
    "no add vocab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося додати словниковий запас до уроку."])},
    "no upload vocab image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося завантажити зображення словника."])},
    "fail signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося вийти."])},
    "invalid user/pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильне ім'я користувача або пароль!"])},
    "error ms login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Під час входу в Microsoft сталася помилка!"])},
    "no password change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль не можна змінити!"])},
    "password changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль успішно змінено!"])},
    "valid email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть дійсний e-mail"])},
    "del user?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви справді хочете видалити користувача?"])}
  }
}