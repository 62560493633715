import axios from 'axios';
import { errorPrepend } from '@/models/index';
import i18n from '@/i18n';

export class Settings {
  public static UPDATE_SUCCESS = i18n.global.t('messages.settings saved');
  public static UPDATE_ERROR = i18n.global.t('messages.error save settings') + errorPrepend();
  public static LOAD_ERROR = i18n.global.t('messages.error load settings') + errorPrepend();
  constructor (
    public preferredLanguage: string,
    public preferredVoice: string,
    public audiosWhileLearning: boolean,
    public superlearningId: number,
    public vocabsPerStage: number
  ) {}

  static async getUserSettings (): Promise<Settings> {
    const { data } = await axios.get('api/user/settings');
    return data;
  }

  static async update (settings: Settings): Promise<void > {
    const { data } = await axios.put('api/user/settings', settings);
    return data;
  }
}
