import axios from 'axios';
import { EditableUser } from '@/models/User';
import { errorPrepend } from '@/models/index';
import i18n from '@/i18n';

export class Organizer extends EditableUser {
  static ADD_ERROR = i18n.global.t('messages.no organizer add') + errorPrepend();
  static ADD_MULTIPLE_ERROR = i18n.global.t('messages.no organizers add') + errorPrepend();
  static LOAD_ERROR = i18n.global.t('messages.no organizer load') + errorPrepend();
  static LOAD_MULTIPLE_ERROR = i18n.global.t('messages.no organizers load') + errorPrepend();
  static DELETE_ERROR = i18n.global.t('messages.error del organizer') + errorPrepend();
  static DELETE_MULTIPLE_ERROR = i18n.global.t('messages.error del organizers') + errorPrepend();
  static UPDATE_ERROR = i18n.global.t('messages.no organizer change') + errorPrepend();
  static UPDATE_MULTIPLE_ERROR = i18n.global.t('messages.no organizers change') + errorPrepend();

  constructor (public id: number,
               public firstname: string,
               public lastname: string,
               public email: string,
               public schoolId?: number,
               public password?: string) {
    super(id, firstname, lastname, email, password);
  }

  static async getAll (schoolId: number): Promise<Organizer[]> {
    const { data } = await axios.get(`api/school/${schoolId}/organizer/all`);
    return data;
  }

  static async getById (organizerId: number): Promise<Organizer> {
    const { data } = await axios.get(`api/organizer/${organizerId}`);
    return data;
  }

  static async update (organizer: Organizer): Promise<void> {
    const { data } = await axios.put('api/organizer', organizer);
    return data;
  }

  static async create (organizers: Organizer[]): Promise<void> {
    const { data } = await axios.post('api/organizer', organizers);
    return data;
  }

  static async delete (organizerId: number): Promise<void> {
    const { data } = await axios.delete(`api/organizer/${organizerId}`);
    return data;
  }
}
