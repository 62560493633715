export default {
  "messages": {
    "Anmeldung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registration"])},
    "SZ Wordly registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SZ-Wordly registration"])},
    "Welcome back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome back"])},
    "or with local user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or with local user"])},
    "SZ-Wordly - vocabulary trainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SZ-Wordly - Vocabulary trainer"])},
    "save email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember email"])},
    "E-mail / username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail / username"])},
    "Sign-Out from SZ Wordly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign-Out from SZ Wordly"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success!"])},
    "Successfully logged off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully logged off!"])},
    "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "Sign in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "Administrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
    "Delete?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete?"])},
    "Delete book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Should the book really be deleted?"])},
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["menu"])},
    "source language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source language"])},
    "target language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target language"])},
    "Publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publisher"])},
    "Startseite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home page"])},
    "book templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["book templates"])},
    "school administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["school administration"])},
    "audio management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["audio management"])},
    "Einstellungen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "books management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["books management"])},
    "Available Books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Books"])},
    "Create new book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new book"])},
    "Surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname"])},
    "Extinguish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "Change Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
    "old password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old password"])},
    "New Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "Repeat new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat new password"])},
    "password length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password length"])},
    "Show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "first name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname"])},
    "e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "Create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "search term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search term"])},
    "Create new school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new school"])},
    "Create school button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create school button"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "create school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" create school"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "Add new superlearning music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new superlearning music"])},
    "music name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Music name"])},
    "name of the new music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name of the new music"])},
    "Add to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to"])},
    "Existing learning music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existing learning music"])},
    "Access failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access failed"])},
    "mistake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error!"])},
    "You do not have access to this resource!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have access to this resource!"])},
    "Sign out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])},
    "Schüler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student"])},
    "your profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Profile"])},
    "E-mail (username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail (username"])},
    "voice output": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["voice output"])},
    "superlearning music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["superlearning music"])},
    "Voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voice"])},
    "masculine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["masculine"])},
    "Feminine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feminine"])},
    "Number of vocabulary per learning unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of vocabulary per learning unit"])},
    "my courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Courses"])},
    "There are no courses available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no courses available"])},
    "Sign out of school account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out of school account"])},
    "start training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start training"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["description"])},
    "Select vocabulary:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select vocabulary:"])},
    "three choices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the correct translation of the word from the three choices provided."])},
    "Form vocabulary pairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form vocabulary pairs by clicking on the source language and target language of the word."])},
    "training completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training completed!"])},
    "You have completed x of x": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have completed x of x exercises correctly without errors..."])},
    "Repeat Lecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat Lecture"])},
    "Back to the lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to the lesson"])},
    "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress"])},
    "No picture available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No picture available"])},
    "form vocabulary pairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["form vocabulary pairs"])},
    "Choose word based image displayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the correct word based on the image displayed and the audio you hear. Also, try to repeat the word you heard."])},
    "See, hear & select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See, hear & select vocabulary:"])},
    "Write the word that can be seen in the picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write the word that can be seen in the picture and heard in the audio in the text box provided. Also, try to repeat the word you heard."])},
    "License:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" License:"])},
    "Choose vocabulary based on image displayed.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Choose the correct vocabulary based on the image displayed."])},
    "See picture & select vocabulary:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See picture & select vocabulary:"])},
    "Check answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check answers"])},
    "write vocabulary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["write vocabulary"])},
    "Write translation text box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write the correct translation of the word in the provided text box."])},
    "Which units learn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which units would you like to learn?"])},
    "Lehrer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher"])},
    "Create course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create course"])},
    "course name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Name"])},
    "Description:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description:"])},
    "Source language:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source language:"])},
    "Add Lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Lesson"])},
    "Create Lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Lesson"])},
    "Vocabulary list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vocabulary list*"])},
    "*optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*optional"])},
    "Apply template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply template"])},
    "insert lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["insert lessons"])},
    "lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lessons"])},
    "course members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Members"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
    "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates"])},
    "No course members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no course members"])},
    "Choose a file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a file"])},
    "Upload vocabulary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload vocabulary"])},
    "Delete Courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Course(s)"])},
    "Which courses delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which courses do you want to delete?"])},
    "confirm selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirm selection"])},
    "delete lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete lessons"])},
    "Which lessons delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which lessons do you want to delete?"])},
    "select class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select class"])},
    "select students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select students"])},
    "Add student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add student"])},
    "course member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["course member"])},
    "Organisator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizer"])},
    "change class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change class"])},
    "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["students"])},
    "New name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New name"])},
    "change name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["change name"])},
    "NoLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NoLogin"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team"])},
    "Common": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common"])},
    "add a picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a picture"])},
    "login with school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with school account"])},
    "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error 404: Page not found"])},
    "page not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This page could not be found"])},
    "back to home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to Homepage"])},
    "back to login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Login"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
    "no learn music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your browser does not support learning music"])},
    "delete music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete music"])},
    "new class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new class"])},
    "delete class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete class"])},
    "name of new class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the new class"])},
    "remove student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove student"])},
    "no students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no students in this class"])},
    "show warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show warnings"])},
    "export new passwords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export new passwords"])},
    "migrate user ad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migrate users and groups from Microsoft Active Directory"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "training mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["training mode"])},
    "no vocab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no vocabulary available"])},
    "enable editing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable editing"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discard"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "no book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no books available"])},
    "save changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])},
    "csv files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV File(s)"])},
    "name of lecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of lecture"])},
    "generate new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate new password"])},
    "reset values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset values"])},
    "delete user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete user"])},
    "no desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This course has no description"])},
    "del lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete lesson"])},
    "hide lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide lesson"])},
    "show lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show lesson"])},
    "edit lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit lesson"])},
    "no lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no lessons available!"])},
    "discard changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discard changes"])},
    "pic desc and license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Picture description/License"])},
    "enter license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the license in the field:"])},
    "add student to class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add student to class"])},
    "Class of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class of "])},
    "class name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class name"])},
    "See, hear & write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See, hear & write vocabulary"])},
    "See pic & write vocab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See the picture and write the vocabulary:"])},
    "write word from picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write the word you see on screen in the textfield provided."])},
    "check answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check answer"])},
    "diabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabled"])},
    "save settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save settigns"])},
    "no students course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no students in this course!"])},
    "no statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no statistics available for this course!"])},
    "no templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no templates for this language combination!"])},
    "change course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change course"])},
    "del user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete user"])},
    "add member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add member"])},
    "add members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add members"])},
    "add whole class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An entire class can be added by not selecting a student!"])},
    "information1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The aim of this diploma thesis is to develop an online learning platform that enables students to learn more efficiently while keeping the organizational effort for teachers as low as possible. The most traditional way of learning vocabulary is to use a vocabulary book. This method causes little effort for the teacher, but learning is very tedious and inefficient."])},
    "information2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The biggest advantage of an online trainer compared to the vocabulary book is the way it is learned. The platform allows the use of a wide variety of learning methods, taking into account the different types of learners. The students can learn the vocabulary from their textbooks at any time, because the teacher provides the students with the vocabulary in pre-made lists and can continuously monitor the learning progress of the students."])},
    "information3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With the help of a learning algorithm that calculates in the background which vocabulary is selected, individual training is made possible that is specifically tailored to the user. The algorithm prioritizes vocabulary that the user has not yet mastered so well and checks it accordingly more often."])},
    "information4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auditory learners in particular can benefit greatly from listening to music while learning. The brain learns much more effectively when it is stimulated with noise while learning, but this only applies to an even and balanced background noise. This creates a working atmosphere that promotes concentration without causing distractions. That's why SZ-Wordly also offers superlearning music. Four tracks are provided:"])},
    "our idea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Idea"])},
    "no superlearning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no superlearning music available."])},
    "no cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No cover"])},
    "book name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of book"])},
    "publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of publisher"])},
    "add csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add CSV File "])},
    "Benutzerverwaltung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Management"])},
    "Klassenverwaltung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class Management"])},
    "Kursübersicht": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Overview"])},
    "Meine Kurse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Courses"])},
    "Büchervorlagen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book Templates"])},
    "Schulverwaltung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Management"])},
    "Audioverwaltung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio Management"])},
    "Bücherverwaltung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book Management"])},
    "Kursdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Details"])},
    "Lektionsdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture Details"])},
    "Lernen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learning"])},
    "Buchdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book Details"])},
    "Projektleiter, Frontend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Manager, Frontend"])},
    "Frontend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frontend"])},
    "Backend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend"])},
    "music1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrumental Baroque - Track"])},
    "music2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrumental Classic - Track"])},
    "music3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ambient Music - Track"])},
    "music4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nature Sounds / Ambient Noise - Track"])},
    "del music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete the music?"])},
    "no return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action cannot be reversed!"])},
    "no changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Passwords were changed!"])},
    "edit school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit School"])},
    "del student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete student from class"])},
    "del school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete this school?"])},
    "school info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Informations"])},
    "you have": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have"])},
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
    "exercises solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exercises sovled without error..."])},
    "wrong translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately this is wrong, the correct translation of "])},
    "is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is"])},
    "password matching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password must match!"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information!"])},
    "confirm del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "session timeout text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The session timed out. You have to sign in again!"])},
    "session timeout title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session timed out"])},
    "not log in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not logged in"])},
    "log in missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No login"])},
    "Seite nicht gefunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
    "no book change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The book could not be changed. "])},
    "no book del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" The book could not be deleted. "])},
    "no book create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Book could not be created. "])},
    "no book del full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The books could not be completely deleted. "])},
    "no book load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The book could not be loaded. "])},
    "no books load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The books could not be loaded. "])},
    "no class add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The class could not be added. "])},
    "no classes add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The classes could not be added. "])},
    "no class load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The class could not be loaded . "])},
    "no classes load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The classes could not be loaded. "])},
    "error class load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error removing the selected class. "])},
    "error classes load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error removing the selected classes. "])},
    "no class change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The class could not be changed. "])},
    "fill all fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in all fields! "])},
    "no data load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" The data could not be loaded. "])},
    "file to big": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The uploaded file(s) are too big! Maximum size 40 MiB! "])},
    "no course load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The course could not be loaded. "])},
    "no course add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The course could not be added. "])},
    "course error del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error deleting the selected courses. "])},
    "course error change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error changing course. "])},
    "try again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try again! "])},
    "no lesson add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" The lesson could not be added. "])},
    "no lessons load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lessons could not be loaded. "])},
    "no lesson load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" The lesson could not be loaded . "])},
    "error del lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error deleting the selected lessons. "])},
    "no lesson change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The lesson could not be changed. "])},
    "no statistic load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The statistics could not be loaded. "])},
    "template add course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template has been added to the course "])},
    "error add template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error adding template. "])},
    "no organizer add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The organizer could not be added. "])},
    "no organizers add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The organizers could not be added. "])},
    "no organizer load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The organizer could not be loaded . "])},
    "no organizers load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The organizers could not be loaded. "])},
    "error del organizer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error removing the selected organizer. "])},
    "error del organizers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error removing the selected organizers. "])},
    "no organizer change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The organizer could not be changed. "])},
    "no organizers change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The organizers could not be changed. "])},
    "no school add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The school could not be added. "])},
    "no school load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The school could not be loaded . "])},
    "no schools load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The schools could not be loaded. "])},
    "error del school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error removing the selected school. "])},
    "error school change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The school could not be changed. "])},
    "request a minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This request may take up to a minute, please be patient! "])},
    "settings saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The settings have been saved. "])},
    "error save settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error saving settings. "])},
    "error load settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error loading settings. "])},
    "error load statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The statistics could not be loaded. "])},
    "no student add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The student could not be added. "])},
    "no students add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The students could not be added. "])},
    "no student load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The student could not be loaded . "])},
    "no students load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The students could not be loaded. "])},
    "error del student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error removing the selected student. "])},
    "error del students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error removing the selected students. "])},
    "no student change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The student could not be changed. "])},
    "no students class change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The student's class could not be changed. "])},
    "no students change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The students could not be changed. "])},
    "no start training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training mode could not be started. "])},
    "no del audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" The audio could not be deleted. "])},
    "no upload audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The audio could not be uploaded. "])},
    "no load audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The audio files could not be loaded. "])},
    "no teacher add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The teacher could not be added. "])},
    "no teachers add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The teachers could not be added. "])},
    "no teacher load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The teacher could not be loaded . "])},
    "no teachers load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The teachers could not be loaded. "])},
    "error del teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error removing the selected teacher. "])},
    "error del teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error removing the selected teachers. "])},
    "no change teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The teacher could not be changed. "])},
    "no change teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The teacher could not be changed. "])},
    "no add vocab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The vocabulary could not be added to the lesson. "])},
    "no upload vocab image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The vocabulary image could not be uploaded. "])},
    "fail signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out failed. "])},
    "invalid user/pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid username or password! "])},
    "error ms login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred during the Microsoft login! "])},
    "no password change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password could not be changed! "])},
    "password changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password has been changed succesfully! "])},
    "valid email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid e-mail"])},
    "del user?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete the user?"])},
    "del lesson?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete this lesson?"])},
    "BuchDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book Details"])},
    "create user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create user"])}
  }
}