import axios from 'axios';

export enum LanguageShort {
  de = 'de', en = 'en', es = 'es', fr = 'fr',
  bg = 'bg', bs = 'bs', cs = 'cs', el = 'el',
  fa = 'fa', hr = 'hr', hu = 'hu', it = 'it',
  la = 'la', pl = 'pl', pt = 'pt', ro = 'ro',
  ru = 'ru', sk = 'sk', sl = 'sl', sq = 'sq',
  sr = 'sr', tr = 'tr'
}

export class Language {
  constructor (public name: LanguageShort,
               public fullname: string,
               public imageUrl: string) {
  }

  static async getAllLanguages (): Promise<Language[]> {
    const { data } = await axios.get('api/language/all');
    return data;
  }
}
