import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-1cab0d8a")
const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body row" }
const _hoisted_3 = { class: "card-title text-center col-12" }
const _hoisted_4 = { class: "card shadow-lg" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "card-body px-2" }
const _hoisted_7 = { class: "card-text text-center font-weight-bold text-wrap" }
const _hoisted_8 = { class: "card-text text-center" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t("messages.team")), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.team, (member) => {
        return (_openBlock(), _createElementBlock("div", {
          key: member.name,
          class: "col-12 col-sm-6 col-xl-3 mb-4 mb-xl-0"
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("img", {
              class: "card-img-top",
              src: `/assets/teamImg/${member.image}`,
              alt: member.name
            }, null, 8, _hoisted_5),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(member.name), 1),
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t("messages."+member.role)), 1)
            ])
          ])
        ]))
      }), 128))
    ])
  ]))
}