
import { defineComponent } from 'vue';
import InformationCard from '@/components/noLogin/InformationCard.vue';
import AboutCard from '@/components/noLogin/AboutCard.vue';
import ContactCard from '@/components/noLogin/ContactCard.vue';

export default defineComponent({
  name: 'Home',
  components: { ContactCard, AboutCard, InformationCard }
});
