
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { languageMixin } from '@/mixins/languageMixin';
import { showError, showSuccess } from '@/sweetalert2/templates.ts';
import { User, errorPrepend } from '@/models';

export default defineComponent({
  mixins: [languageMixin],
  computed: {
    defaultUrl () {
      return User.getLandingPage(this.user.type || 'student');
    },
    ...mapState({
      user: 'user',
      loggedIn: 'loggedIn',
      adLogin: 'adLogin'
    })
  },
  methods: {
    async logout () {
      try {
        await this.$store.dispatch('logout');
        await showSuccess(this.$t("messages.Successfully logged off"));
        location.href = '/login/';
      } catch (e) {
        await showError(this.$t("messages.fail signout") + errorPrepend());
      }
    },
    async logoutAd () {
      await this.$store.dispatch('logoutAd');
    }
  }
});
