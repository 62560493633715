import { RouteLocationNormalized, Router } from 'vue-router';
import store from '@/store';
import Swal from '@/sweetalert2';
import { CommonError, User, UserType } from '@/models';
import { showError } from '@/sweetalert2/templates.ts';
import i18n from '@/i18n';

function trailingSlash (str: string): string {
  return str.endsWith('/') ? str : str + '/';
}

async function noAccess (userType: UserType): Promise<string> {
  const result = await Swal.fire({
    confirmButtonText: i18n.global.t('messages.Startseite'),
    cancelButtonText: i18n.global.t('messages.Sign out'),
    showCancelButton: true,
    title: i18n.global.t('messages.mistake'),
    text: i18n.global.t('messages.You do not have access to this resource!'),
    icon: 'info'
  });
  if (result.isConfirmed) {
    return User.getLandingPage(userType);
  } else {
    await store.dispatch('logout');
    location.href = '/login/';
    return '';
  }
}

export function registerGuard (router: Router): void {
  router.beforeEach(async (to: RouteLocationNormalized) => {
    if (!to.path.endsWith('/')) {
      return trailingSlash(to.fullPath);
    }
    let userType = store.state.user.type;
    if (userType === undefined) {
      try {
        await store.dispatch('refreshed');
        userType = store.state.user.type;
      } catch (e) {
        await showError(CommonError.LOAD_ERROR);
        return false;
      }
    }
    if (to.meta.users && !((to.meta.users).includes(userType))) {
      return await noAccess(userType);
    }
    if (to.meta.requiresAuth && !store.state.loggedIn) {
      await Swal.fire({
        icon: 'warning',
        text: i18n.global.t('messages.not log in'),
        title: i18n.global.t('messages.log in missing')
      });
      location.href = '/login/';
      return;
    }
    return true;
  });
}
