import { errorPrepend, LanguageShort, Lesson } from '@/models';
import axios from 'axios';
import toFormData from '@/models/FormData';
import i18n from '@/i18n';

export class Book {
  static UPDATE_ERROR = i18n.global.t('messages.no book change') + errorPrepend();
  static DELETE_ERROR = i18n.global.t('messages.no book del') + errorPrepend();
  static CREATE_ERROR = i18n.global.t('messages.no book create') + errorPrepend();
  static DELETE_MULTIPLE_ERROR = i18n.global.t('messages.no book del full') + errorPrepend();
  static LOAD_ERROR = i18n.global.t('messages.no book load') + errorPrepend();
  static LOAD_MULTIPLE_ERROR = i18n.global.t('messages.no books load') + errorPrepend();
  static ACCESS_ERROR = i18n.global.t('messages.no book del full') + errorPrepend();

  constructor (
    public id: number, public name: string,
    public languageFrom: LanguageShort,
    public languageTo: LanguageShort,
    public publisher: string,
    public imageUrl: string,
    public cover?: File) {
  }

  static async delete (book: Book): Promise<void> {
    const { data } = await axios.delete(`api/book/${book.id}`);
    return data;
  }

  static async getAll (): Promise<Book[]> {
    const { data } = await axios.get('api/book/all');
    return data;
  }

  static async create (book: Book): Promise<{ id: number }> {
    const { data } = await axios.post('api/book', book);
    return data;
  }

  static async update (book: Book): Promise<void> {
    const { data } = await axios.put('api/book', book);
    return data;
  }

  static async updateCover (book: Book, file: File): Promise<void> {
    const formData = toFormData({ cover: file, id: book.id });
    const { data } = await axios.post('api/book/cover', formData);
    return data;
  }

  static async addLesson (book: Book, lessonName: string): Promise<{ id: number }> {
    const { data } = await axios.post('api/book/lesson', {
      name: lessonName,
      bookId: book.id,
      languageFrom: book.languageFrom,
      languageTo: book.languageTo
    });
    return data;
  }

  static async getLessons (bookId: number): Promise<Lesson[]> {
    const { data } = await axios.get(`api/book/${bookId}/lesson/all`);
    return data;
  }

  static async getById (bookId: number): Promise<Book> {
    const { data } = await axios.get(`api/book/${bookId}`);
    return data;
  }
}
