import { errorPrepend, Translation } from '@/models/index';
import axios from 'axios';
import i18n from '@/i18n';

export class Study {
  static START_FAILED_ERROR = i18n.global.t('messages.no start training') + errorPrepend();

  static async learn (translation: Translation, term: string): Promise<void> {
    const { data } = await axios.put('api/learn', { fromId: translation.fromId, toId: translation.toId, term });
    return data;
  }
}

export interface StudyGuess {
  translation: Translation,
  term: string,
  correct: boolean
}
export enum LearningMethod {
  oneOfThree = 'OneOfThree',
  imageOneOfThree = 'ImageOneOfThree',
  wordCluster = 'WordCluster',
  wordList = 'WordList',
  writeWord = 'WriteWord',
  imageWriteWord = 'ImageWriteWord',
  imageAudioWriteWord = 'ImageAudioWriteWord',
  imageAudioOneOfThree = 'ImageAudioOneOfThree',
  none = 'None'
}

export interface Stage {
  minLevel: number,
  maxLevel: number,
  method: LearningMethod,
  learnsAmount: number,
  usesAmount: number,
  learnsTranslations?: Translation[],
  usesTranslations?: Translation[],
  allTranslations: Translation[],
  requiresAudio?: boolean,
  requiresImage?: boolean
}

export const emptyStage = {
  minLevel: 0,
  maxLevel: 0,
  method: 'None',
  learnsAmount: 0,
  usesAmount: 0,
  allTranslations: []
};

export const oneOfThree = {
  method: LearningMethod.oneOfThree,
  minLevel: 0,
  maxLevel: 30,
  learnsAmount: 1,
  usesAmount: 3,
  allTranslations: []
};
export const imageOneOfThree = {
  method: LearningMethod.imageOneOfThree,
  minLevel: 31,
  maxLevel: 70,
  learnsAmount: 1,
  usesAmount: 3,
  allTranslations: [],
  requiresImage: true
};
export const imageAudioOneOfThree = {
  method: LearningMethod.imageAudioOneOfThree,
  minLevel: 31,
  maxLevel: 70,
  learnsAmount: 1,
  usesAmount: 3,
  allTranslations: [],
  requiresAudio: true,
  requiresImage: true
};
export const wordCluster = {
  method: LearningMethod.wordCluster,
  minLevel: 0,
  maxLevel: 30,
  learnsAmount: 3,
  usesAmount: 3,
  allTranslations: []
};
export const writeWord = {
  method: LearningMethod.writeWord,
  minLevel: 31,
  maxLevel: 70,
  learnsAmount: 1,
  usesAmount: 1,
  allTranslations: []
};
export const imageWriteWord = {
  method: LearningMethod.imageWriteWord,
  minLevel: 71,
  maxLevel: 100,
  learnsAmount: 1,
  usesAmount: 1,
  allTranslations: [],
  requiresImage: true
};
export const imageAudioWriteWord = {
  method: LearningMethod.imageAudioWriteWord,
  minLevel: 71,
  maxLevel: 100,
  learnsAmount: 1,
  usesAmount: 1,
  allTranslations: [],
  requiresImage: true
};
