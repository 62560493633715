import { VueElement } from 'vue';
import { createI18n, LocaleMessages, VueI18nOptions, VueMessageType } from 'vue-i18n';
import English from "./locales/en-Us.json";
import Deutsch from "./locales/de.json";
import French from "./locales/fr.json";
import Italian from "./locales/it.json";
import Spanish from "./locales/es.json";
import Ukraine from "./locales/ukr.json";

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */

function loadLocaleMessages (): LocaleMessages<VueMessageType> {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages<VueMessageType> = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default;
    }
  });
  return messages;
}

export default createI18n({
  legacy: false,
  locale: 'Deutsch',
  fallbackLocale: 'Deutsch',
  messages: {
    Deutsch: Deutsch,
    English: English,
    French: French,
    Italian: Italian,
    Spanish: Spanish,
    Ukraine: Ukraine
  }
});
