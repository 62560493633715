import { errorPrepend } from '@/models/index';
import axios from 'axios';
import i18n from '@/i18n';

export class Translation {
  static ADD_ERROR = i18n.global.t('messages.no add vocab') + errorPrepend();
  static IMAGE_UPLOAD_ERROR = i18n.global.t('messages.no upload vocab image') + errorPrepend();

  constructor (public fromId: number,
              public fromName: string,
              public toId: number,
              public toName: string,
              public level: number,
              public fromAudioUrl: string,
              public toAudioUrl: string,
              public imageUrl: string,
              public imageDescription: string,
              public invertedValue?: number,
              public newTranslation?: boolean,
              public guess?: string,
              public lastCorrect?: string,
              public image?: File
  ) {
  }

  static async updateLessonTranslations (lessonId: number, translations: { vocabFrom: string, vocabTo: string, imageUrl?: string | null }[]): Promise<void> {
    const { data } = await axios.put('api/lesson/translations', {
      lessonId, translations
    });
    return data;
  }

  static async getTranslationsFromLesson (lessonId: number): Promise<Translation[]> {
    const { data } = await axios.get(`api/lesson/${lessonId}/translation/all`);
    return data;
  }
}
