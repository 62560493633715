
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AboutCard',
  data () {
    return {
      basepath: '@/assets/img/',
      team: [
        { name: 'Felix Brandl', image: 'brandl.jpg', role: 'Frontend' },
        { name: 'René Danzinger', image: 'danzinger.jpg', role: 'Backend' },
        { name: 'Johannes Riegler', image: 'riegler.jpg', role: 'Projektleiter, Frontend' },
        { name: 'Julian Weissensteiner', image: 'weissensteiner.jpg', role: 'Frontend' }
      ]
    };
  }
});
