import axios from 'axios';
import { errorPrepend } from '@/models/index';
import i18n from '@/i18n';

export class School {
  static ADD_ERROR = i18n.global.t('messages.no school add') + errorPrepend();
  static LOAD_ERROR = i18n.global.t('messages.no school load') + errorPrepend();
  static LOAD_MULTIPLE_ERROR = i18n.global.t('messages.no schools load') + errorPrepend();
  static DELETE_ERROR = i18n.global.t('messages.error del school') + errorPrepend();
  static UPDATE_ERROR = i18n.global.t('messages.error school change') + errorPrepend();
  static LONG_REQUEST = i18n.global.t('messages.request a minute');

  constructor (public id: number,
              public address: string,
              public name: string) {
  }

  static async getAll (): Promise<School[]> {
    const { data } = await axios.get('api/school/all');
    return data;
  }

  static async getById (schoolId: number): Promise<School> {
    const { data } = await axios.get(`api/school/${schoolId}`);
    return data;
  }

  static async update (school: School): Promise<void> {
    const { data } = await axios.put('api/school', school);
    return data;
  }

  static async create (school: School): Promise<void> {
    const { data } = await axios.post('api/school', school);
    return data;
  }

  static async delete (schoolId: number): Promise<void> {
    const { data } = await axios.delete(`api/school/${schoolId}`);
    return data;
  }

  static async updateUsersAndGroups (): Promise<void> {
    const { data } = await axios.put('api/adUsersAndGroups');
    return data;
  }
}
