import { createStore, Store } from 'vuex';
import { Course, Language, Settings, SideNavItem, User, UserType } from '@/models';
import { InjectionKey } from 'vue';
import { loginRequest, logoutRequest, msalInstance } from '@/msal/authConfig';

// TODO: https://www.codeproject.com/Tips/5295301/Correctly-Typing-Vuex-with-TypeScript
export interface State {
  user: User,
  adLogin: boolean
  loggedIn: boolean,
  sideNavVisible: boolean,
  userProfileVisible: boolean,
  userSettings: Settings,
  languages: Language[],
  courses: Course[],
  sideNavItems: SideNavItem[],
}

export const key: InjectionKey<Store<State>> = Symbol('');

export default createStore<State>({
  state: {
    user: {} as User,
    loggedIn: false,
    adLogin: false,
    sideNavVisible: true,
    userProfileVisible: false,
    userSettings: {} as Settings,
    languages: [] as Language[],
    courses: [] as Course[],
    sideNavItems: [] as SideNavItem[]
  },
  mutations: {
    toggleSideNav (state) {
      state.sideNavVisible = !state.sideNavVisible;
    },
    toggleUserProfile (state) {
      state.userProfileVisible = !state.userProfileVisible;
    }
  },
  actions: {
    async getCourses (context) {
      context.state.courses = await Course.getAll();
    },
    async getUser (context) {
      const user = await User.getUser();
      user.type = localStorage.getItem('userType') as UserType;
      context.state.user = user;
    },
    async getSettings (context) {
      context.state.userSettings = await Settings.getUserSettings();
    },
    async getLanguages (context) {
      context.state.languages = (await Language.getAllLanguages())
        .sort((a, b) => a.fullname.localeCompare(b.fullname));
    },
    async logout ({ dispatch }) {
      await User.deleteSession();
      dispatch('deleteData');
    },
    async logoutAd ({ dispatch }) {
      await dispatch('logout');
      await msalInstance.logoutRedirect(logoutRequest);
    },
    deleteData ({ state }) {
      state.user = {} as User;
      state.courses = [];
      state.loggedIn = false;
      state.adLogin = false;
      localStorage.setItem('hasSession', String(false));
      localStorage.setItem('adLogin', String(false));
      localStorage.removeItem('userType');
    },
    async login ({ dispatch, state }, payload) {
      const data = await User.createSession(payload);
      this.state.user.type = data.usertype as UserType;
      localStorage.setItem('userType', data.usertype);
      localStorage.setItem('hasSession', String(true));
      await dispatch('getData');
      state.loggedIn = true;
      await dispatch('setSideNav');
    },
    async createSessionFromJwt ({ dispatch, state }, payload) {
      state.user.type = payload;
      localStorage.setItem('userType', payload);
      localStorage.setItem('hasSession', String(true));
      localStorage.setItem('adLogin', String(true));
      await dispatch('getData');
      state.loggedIn = true;
      state.adLogin = true;
      await dispatch('setSideNav');
    },
    async loginAd ({ dispatch }) {
      const response = await msalInstance.acquireTokenSilent({
        ...loginRequest
      }).catch(async (e: unknown) => {
        await msalInstance.loginRedirect(loginRequest);
        throw e;
      });
      const data = await User.loginAd(response.accessToken);
      await dispatch('createSessionFromJwt', data.usertype);
    },
    async refreshed ({ dispatch, state }) {
      const hasSession = localStorage.getItem('hasSession');
      const adLogin = localStorage.getItem('adLogin');
      if (hasSession != null && JSON.parse(hasSession)) {
        await dispatch('getData');
        state.loggedIn = true;
        await dispatch('setSideNav');
        state.user.type = localStorage.getItem('userType') as UserType;
      } else {
        return;
      }
      if (adLogin != null && JSON.parse(adLogin)) {
        state.adLogin = true;
      }
    },
    async getData ({ dispatch, state }) {
      state.user.type = localStorage.getItem('userType') as UserType ?? 'student';
      await dispatch('getUser');
      switch (state.user.type) {
        case 'student':
          await dispatch('getSettings');
          await dispatch('getCourses');
          await dispatch('getLanguages');
          break;
        case 'teacher':
          await dispatch('getCourses');
          await dispatch('getLanguages');
          break;
        case 'administrator':
          await dispatch('getLanguages');
          break;
        case 'organizer':
          break;
        default:
          await dispatch('getCourses');
          await dispatch('getLanguages');
      }
    },
    setSideNav ({ state }) {
      const items: SideNavItem[] = [];
      items.push({
        icon: 'fa-info-circle',
        text: 'Startseite',
        link: '/'
      });
      switch (state.user.type) {
        case 'student':
        case 'teacher':
          items.push({ icon: 'fa-home', text: 'Kursübersicht', link: '/dashboard/' });
          items.push({
            icon: 'fa-book',
            text: 'Meine Kurse',
            link: '',
            children: state.courses.map(c => {
              return { text: c.name, link: `/course/${c.id}/` };
            })
          });
          break;
        case 'administrator':
          items.push({
            icon: 'fa-book',
            text: 'Büchervorlagen',
            link: '/books/'
          });
          items.push({
            icon: 'fa-users',
            text: 'Schulverwaltung',
            link: '/schools/'
          });
          items.push({
            icon: 'fa-music',
            text: 'Audioverwaltung',
            link: '/superlearning/'
          });
          break;
        case 'organizer':
          items.push({ icon: 'fa-user', text: 'Benutzerverwaltung', link: '/users/' });
          items.push({ icon: 'fa-users', text: 'Klassenverwaltung', link: '/classes/' });
          break;
        default:
          break;
      }
      items.push({ icon: 'fa-cog', text: 'Einstellungen', link: '/settings/' });
      state.sideNavItems = items;
    }
  },
  modules: {}
});
