export default {
  "messages": {
    "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["error 404"])},
    "Anmeldung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registro"])},
    "SZ Wordly registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro SZ-Wordly"])},
    "Welcome back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido de nuevo"])},
    "or with local user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o con usuario local"])},
    "SZ-Wordly - vocabulary trainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SZ-Wordly - Entrenador de vocabulario"])},
    "save email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recordar correo electronico"])},
    "E-mail / username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico / nombre de usuario"])},
    "Sign-Out from SZ Wordly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salir de SZ Wordly"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Éxito!"])},
    "Successfully logged off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Cierre de sesión con éxito!"])},
    "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "Sign in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrarse"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "Administrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador"])},
    "Delete?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Borrar?"])},
    "Delete book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Debería realmente borrarse el libro?"])},
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["menú"])},
    "source language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lenguaje fuente"])},
    "target language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lengua de llegada"])},
    "Publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor"])},
    "Startseite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["página de inicio"])},
    "book templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plantillas de libros"])},
    "school administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["administración escolar"])},
    "audio management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gestión de audio"])},
    "Einstellungen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustes"])},
    "books management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gestión de libros"])},
    "Available Books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libros disponibles"])},
    "Create new book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear nuevo libro"])},
    "Surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
    "Extinguish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar"])},
    "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacto"])},
    "Change Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia la contraseña"])},
    "old password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña anterior"])},
    "New Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva contraseña"])},
    "Repeat new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["repita la nueva contraseña"])},
    "password length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longitud de la contraseña"])},
    "Show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espectáculo"])},
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar"])},
    "first name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primer nombre"])},
    "e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
    "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comportamiento"])},
    "Create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear"])},
    "search term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Término de búsqueda"])},
    "Create new school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear nueva escuela"])},
    "Create school button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear botón de escuela"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
    "create school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["crear escuela"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "Add new superlearning music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar nueva música de superaprendizaje"])},
    "music name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la música"])},
    "name of the new music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nombre de la nueva musica"])},
    "Add to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["añadir"])},
    "Existing learning music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Música de aprendizaje existente"])},
    "Access failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso fallido"])},
    "mistake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Error!"])},
    "You do not have access to this resource!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡No tienes acceso a este recurso!"])},
    "Sign out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["desconectar"])},
    "Schüler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alumno"])},
    "your profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu perfil"])},
    "E-mail (username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico (nombre de usuario"])},
    "voice output": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["salida de voz"])},
    "superlearning music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["musica de superaprendizaje"])},
    "Voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voz"])},
    "masculine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["masculino"])},
    "Feminine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Femenino"])},
    "Number of vocabulary per learning unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de vocabulario por unidad de aprendizaje"])},
    "my courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis cursos"])},
    "There are no courses available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay cursos disponibles"])},
    "Sign out of school account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salir de la cuenta de la escuela"])},
    "start training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empezar a entrenar"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["descripción"])},
    "Select vocabulary:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar vocabulario"])},
    "three choices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elija la traducción correcta de la palabra de las tres opciones proporcionadas."])},
    "Form vocabulary pairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forme pares de vocabulario haciendo clic en el idioma de origen y el idioma de destino de la palabra."])},
    "training completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Entrenamiento completado!"])},
    "You have completed x of x": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has completado x de x ejercicios correctamente sin errores..."])},
    "Repeat Lecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir clase"])},
    "Back to the lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["volver a la lección"])},
    "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progreso"])},
    "No picture available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay imagen disponible"])},
    "form vocabulary pairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["formar pares de vocabulario"])},
    "Choose word based image displayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elija la palabra correcta según la imagen que se muestra y el audio que escucha. Además, trate de repetir la palabra que escuchó."])},
    "See, hear & select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver, escuchar y seleccionar vocabulario"])},
    "Write the word that can be seen in the picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escriba la palabra que se puede ver en la imagen y escuchar en el audio en el cuadro de texto proporcionado. Además, trate de repetir la palabra que escuchó."])},
    "License:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licencia"])},
    "Choose vocabulary based on image displayed.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elija el vocabulario correcto basado en la imagen que se muestra."])},
    "See picture & select vocabulary:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver imagen y seleccionar vocabulario"])},
    "Check answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisar respuestas"])},
    "write vocabulary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["escribir vocabulario"])},
    "Write translation text box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escriba la traducción correcta de la palabra en el cuadro de texto provisto."])},
    "Which units learn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué unidades te gustaría aprender?"])},
    "Lehrer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesor"])},
    "Create course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear curso"])},
    "course name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del curso"])},
    "Description:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
    "Source language:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lenguaje fuente"])},
    "Add Lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar lección"])},
    "Create Lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear lección"])},
    "Vocabulary list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de vocabulario*"])},
    "*optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Opcional"])},
    "Apply template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicar plantilla"])},
    "insert lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["insertar lecciones"])},
    "lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecciones"])},
    "course members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miembros del curso"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estadísticas"])},
    "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantillas"])},
    "No course members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay miembros del curso"])},
    "Choose a file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escoge un archivo"])},
    "Upload vocabulary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subir vocabulario"])},
    "Delete Courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar curso(s)"])},
    "Which courses delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué cursos quieres eliminar?"])},
    "confirm selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirmar selección"])},
    "delete lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eliminar lecciones"])},
    "Which lessons delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué lecciones quieres eliminar?"])},
    "select class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar clase"])},
    "select students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar estudiantes"])},
    "Add student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar estudiante"])},
    "course member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["miembro del curso"])},
    "Organisator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizador"])},
    "change class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio de clase"])},
    "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["estudiantes"])},
    "New name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo nombre"])},
    "change name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cambiar nombre"])},
    "NoLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin inicio de sesión"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipo"])},
    "Common": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Común"])},
    "add a picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir una imagen"])},
    "login with school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión con la cuenta de la escuela"])},
    "page not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta página no se pudo encontrar"])},
    "back to home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regresar a la página principal"])},
    "back to login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrás para iniciar sesión"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
    "no learn music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su navegador no es compatible con el aprendizaje de la música"])},
    "delete music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar música"])},
    "new class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear nueva clase"])},
    "delete class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar clase"])},
    "name of new class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la nueva clase"])},
    "remove student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitar estudiante"])},
    "no students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay estudiantes en esta clase."])},
    "show warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar advertencias"])},
    "export new passwords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar nuevas contraseñas"])},
    "migrate user ad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migrar usuarios y grupos desde Microsoft Active Directory"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comienzo"])},
    "training mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modo de entrenamiento"])},
    "no vocab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay vocabulario disponible"])},
    "enable editing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir la edición"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
    "discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desechar"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "no book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay libros disponibles"])},
    "save changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar cambios"])},
    "csv files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivo(s) CSV"])},
    "name of lecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la conferencia"])},
    "generate new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generar nueva contraseña"])},
    "reset values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer valores"])},
    "delete user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar usuario"])},
    "no desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este curso no tiene descripción"])},
    "del lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar lección"])},
    "hide lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocultar lección"])},
    "show lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar lección"])},
    "edit lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar lección"])},
    "no lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡No hay lecciones disponibles!"])},
    "discard changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descartar los cambios"])},
    "pic desc and license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción de la imagen/Licencia"])},
    "enter license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca la licencia en el campo"])},
    "add student to class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar estudiante a la clase"])},
    "Class of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clase de"])},
    "class name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la clase"])},
    "See, hear & write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver, escuchar y escribir vocabulario"])},
    "See pic & write vocab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mira la imagen y escribe el vocabulario."])},
    "write word from picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escriba la palabra que ve en la pantalla en el campo de texto proporcionado."])},
    "check answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checar respuesta"])},
    "diabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desactivado"])},
    "save settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar ajustes"])},
    "no students course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡No hay estudiantes en este curso!"])},
    "no statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡No hay estadísticas disponibles para este curso!"])},
    "no templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡No hay plantillas para esta combinación de idiomas!"])},
    "change course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio de curso"])},
    "del user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar usuario"])},
    "add member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir miembro"])},
    "add members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir miembros"])},
    "add whole class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Se puede agregar una clase completa sin seleccionar un estudiante!"])},
    "information1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El objetivo de esta tesis de diploma es desarrollar una plataforma de aprendizaje en línea que permita a los estudiantes aprender de manera más eficiente mientras mantiene el esfuerzo organizativo para los profesores lo más bajo posible. La forma más tradicional de aprender vocabulario es usar un libro de vocabulario. Este método supone poco esfuerzo para el profesor, pero el aprendizaje es muy tedioso e ineficaz."])},
    "information2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La mayor ventaja de un entrenador en línea en comparación con el libro de vocabulario es la forma en que se aprende. La plataforma permite el uso de una amplia variedad de métodos de aprendizaje, teniendo en cuenta los diferentes tipos de alumnos. Los estudiantes pueden aprender el vocabulario de sus libros de texto en cualquier momento, ya que el profesor proporciona a los estudiantes el vocabulario en listas prefabricadas y puede monitorear continuamente el progreso de aprendizaje de los estudiantes."])},
    "information3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con la ayuda de un algoritmo de aprendizaje que calcula en segundo plano qué vocabulario se selecciona, se hace posible una formación individual adaptada específicamente al usuario. El algoritmo prioriza el vocabulario que el usuario aún no domina tan bien y lo revisa con más frecuencia."])},
    "information4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los estudiantes auditivos en particular pueden beneficiarse enormemente de escuchar música mientras aprenden. El cerebro aprende mucho más eficazmente cuando se estimula con ruido mientras aprende, pero esto solo se aplica a un ruido de fondo uniforme y equilibrado. Esto crea una atmósfera de trabajo que promueve la concentración sin causar distracciones. Es por eso que SZ-Wordly también ofrece música de superaprendizaje. Se proporcionan cuatro pistas"])},
    "our idea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nuestra idea"])},
    "no superlearning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay música de superaprendizaje disponible."])},
    "no cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin cubierta"])},
    "book name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nombre del libro"])},
    "publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del publicador"])},
    "add csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar archivo CSV"])},
    "Benutzerverwaltung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de usuarios"])},
    "Klassenverwaltung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de la clase"])},
    "Kursübersicht": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen del curso"])},
    "Meine Kurse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis cursos"])},
    "Büchervorlagen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantillas de libros"])},
    "Schulverwaltung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión escolar"])},
    "Audioverwaltung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de audio"])},
    "Bücherverwaltung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de libros"])},
    "Kursdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["detalles del curso"])},
    "Lektionsdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles de la conferencia"])},
    "Lernen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprendizaje"])},
    "Buchdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles del libro"])},
    "Projektleiter, Frontend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerente de Proyecto, Interfaz"])},
    "Frontend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interfaz"])},
    "Backend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["back-end"])},
    "music1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barroco Instrumental - Pista"])},
    "music2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clásico instrumental - Pista"])},
    "music3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Música ambiental - Pista"])},
    "music4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonidos de la naturaleza / Ruido ambiental - Pista"])},
    "del music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿De verdad quieres borrar la música?"])},
    "no return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Esta acción no se puede revertir!"])},
    "no changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡No se cambiaron las contraseñas!"])},
    "edit school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar escuela"])},
    "del student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar estudiante de la clase"])},
    "del school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Realmente quieres eliminar esta escuela?"])},
    "school info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de la escuela"])},
    "you have": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tú tienes"])},
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
    "exercises solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ejercicios resueltos sin error..."])},
    "wrong translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desafortunadamente esto es incorrecto, la traducción correcta de"])},
    "is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["es"])},
    "password matching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡La nueva contraseña debe coincidir!"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Información!"])},
    "confirm del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar"])},
    "session timeout text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La sesión se agotó. ¡Tienes que iniciar sesión de nuevo!"])},
    "session timeout title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se agotó el tiempo de sesión"])},
    "not log in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usted no se ha identificado"])},
    "log in missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin inicio de sesión"])},
    "Seite nicht gefunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página no encontrada"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["y"])},
    "no book change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El libro no se podía cambiar."])},
    "no book del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo eliminar el libro."])},
    "no book create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo crear el libro."])},
    "no book del full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los libros no se pudieron eliminar por completo."])},
    "no book load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo cargar el libro."])},
    "no books load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudieron cargar los libros."])},
    "no class add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo agregar la clase."])},
    "no classes add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudieron agregar las clases."])},
    "no class load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo cargar la clase."])},
    "no classes load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudieron cargar las clases."])},
    "error class load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubo un error al eliminar la clase seleccionada."])},
    "error classes load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubo un error al eliminar las clases seleccionadas."])},
    "no class change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo cambiar la clase."])},
    "fill all fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Por favor rellena todos los campos!"])},
    "no data load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudieron cargar los datos."])},
    "file to big": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Los archivos cargados son demasiado grandes! ¡Tamaño máximo 40 MiB!"])},
    "no course load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo cargar el curso."])},
    "no course add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo agregar el curso."])},
    "course error del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubo un error al eliminar los cursos seleccionados."])},
    "course error change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error al cambiar de rumbo."])},
    "try again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Inténtalo de nuevo!"])},
    "no lesson add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo agregar la lección."])},
    "no lessons load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudieron cargar las lecciones."])},
    "no lesson load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo cargar la lección."])},
    "error del lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubo un error al eliminar las lecciones seleccionadas."])},
    "no lesson change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La lección no se podía cambiar."])},
    "no statistic load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudieron cargar las estadísticas."])},
    "template add course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La plantilla ha sido añadida al curso."])},
    "error add template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al agregar la plantilla."])},
    "no organizer add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo agregar el organizador."])},
    "no organizers add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudieron agregar los organizadores."])},
    "no organizer load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo cargar el organizador."])},
    "no organizers load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudieron cargar los organizadores."])},
    "error del organizer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se produjo un error al eliminar el organizador seleccionado."])},
    "error del organizers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error al eliminar los organizadores seleccionados."])},
    "no organizer change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo cambiar el organizador."])},
    "no organizers change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los organizadores no se pudieron cambiar."])},
    "no school add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo agregar la escuela."])},
    "no school load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo cargar la escuela."])},
    "no schools load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las escuelas no se pudieron cargar."])},
    "error del school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubo un error al eliminar la escuela seleccionada."])},
    "error school change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La escuela no se podía cambiar."])},
    "request a minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta solicitud puede demorar hasta un minuto, ¡tenga paciencia!"])},
    "settings saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los ajustes se han guardado."])},
    "error save settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al guardar la configuración."])},
    "error load settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al cargar la configuración."])},
    "error load statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudieron cargar las estadísticas."])},
    "no student add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo agregar al estudiante."])},
    "no students add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudieron agregar los estudiantes."])},
    "no student load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo cargar el alumno."])},
    "no students load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los estudiantes no se pudieron cargar."])},
    "error del student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubo un error al eliminar al alumno seleccionado."])},
    "error del students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubo un error al eliminar a los estudiantes seleccionados."])},
    "no student change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El estudiante no se puede cambiar."])},
    "no students class change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo cambiar la clase del estudiante."])},
    "no students change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los estudiantes no podían ser cambiados."])},
    "no start training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo iniciar el modo de entrenamiento."])},
    "no del audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo eliminar el audio."])},
    "no upload audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo cargar el audio."])},
    "no load audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudieron cargar los archivos de audio."])},
    "no teacher add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo agregar al maestro."])},
    "no teachers add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudieron agregar los maestros."])},
    "no teacher load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo cargar el profesor."])},
    "no teachers load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudieron cargar los profesores."])},
    "error del teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error al eliminar al profesor seleccionado."])},
    "error del teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubo un error al eliminar a los profesores seleccionados."])},
    "no change teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El profesor no se podía cambiar."])},
    "no change teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El profesor no se podía cambiar."])},
    "no add vocab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo agregar el vocabulario a la lección."])},
    "no upload vocab image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo cargar la imagen del vocabulario."])},
    "fail signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salir fallido."])},
    "invalid user/pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Usuario o contraseña invalido!"])},
    "error ms login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Ocurrió un error durante el inicio de sesión de Microsoft!"])},
    "no password change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡No se pudo cambiar la contraseña!"])},
    "password changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡La contraseña ha sido cambiada con éxito!"])},
    "valid email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca un correo electrónico válido"])},
    "del user?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Realmente desea eliminar el usuario?"])}
  }
}