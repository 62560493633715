export default {
  "messages": {
    "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore 404"])},
    "Anmeldung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registrazione"])},
    "SZ Wordly registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrazione SZ-Wordly"])},
    "Welcome back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ben tornato"])},
    "or with local user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o con utente locale"])},
    "SZ-Wordly - vocabulary trainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SZ-Wordly - Allenatore di vocabolario"])},
    "save email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricorda la posta elettronica"])},
    "E-mail / username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail / nome utente"])},
    "Sign-Out from SZ Wordly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esci da SZ Wordly"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successo!"])},
    "Successfully logged off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnesso con successo!"])},
    "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "Sign in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrazione"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola d'ordine"])},
    "Administrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amministratore"])},
    "Delete?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina?"])},
    "Delete book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il libro dovrebbe davvero essere cancellato?"])},
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["menù"])},
    "source language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linguaggio del codice"])},
    "target language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingua di destinazione"])},
    "Publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editore"])},
    "Startseite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina iniziale"])},
    "book templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modelli di libri"])},
    "school administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amministrazione scolastica"])},
    "audio management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gestione audio"])},
    "Einstellungen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni"])},
    "books management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gestione dei libri"])},
    "Available Books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libri disponibili"])},
    "Create new book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea nuovo libro"])},
    "Surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
    "Extinguish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina"])},
    "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatto"])},
    "Change Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia la password"])},
    "old password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vecchia password"])},
    "New Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova password"])},
    "Repeat new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ripetere la nuova password"])},
    "password length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunghezza della password"])},
    "Show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrare"])},
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificare"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina"])},
    "first name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome di battesimo"])},
    "e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
    "Create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare"])},
    "search term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termine di ricerca"])},
    "Create new school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea una nuova scuola"])},
    "Create school button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulsante Crea scuola"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
    "create school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["creare scuola"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
    "Add new superlearning music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi nuova musica del Superlearning"])},
    "music name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome musicale"])},
    "name of the new music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nome della nuova musica"])},
    "Add to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungere a"])},
    "Existing learning music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musica di apprendimento esistente"])},
    "Access failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso non riuscito"])},
    "mistake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore!"])},
    "You do not have access to this resource!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai accesso a questa risorsa!"])},
    "Sign out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disconnessione"])},
    "Schüler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alunno"])},
    "your profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo profilo"])},
    "E-mail (username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail (nome utente"])},
    "voice output": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uscita vocale"])},
    "superlearning music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["musica del superapprendimento"])},
    "Voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voce"])},
    "masculine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maschile"])},
    "Feminine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Femminile"])},
    "Number of vocabulary per learning unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di vocaboli per unità di apprendimento"])},
    "my courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I miei corsi"])},
    "There are no courses available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non ci sono corsi disponibili"])},
    "Sign out of school account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esci dall'account della scuola"])},
    "start training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizia l'allenamento"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["descrizione"])},
    "Select vocabulary:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il vocabolario"])},
    "three choices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli la traduzione corretta della parola tra le tre scelte fornite."])},
    "Form vocabulary pairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forma coppie di vocaboli facendo clic sulla lingua di origine e sulla lingua di destinazione della parola."])},
    "training completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formazione completata!"])},
    "You have completed x of x": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai completato correttamente x di x esercizi senza errori..."])},
    "Repeat Lecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripeti lezione"])},
    "Back to the lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torniamo alla lezione"])},
    "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progresso"])},
    "No picture available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna immagine disponibile"])},
    "form vocabulary pairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["formare coppie di vocaboli"])},
    "Choose word based image displayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli la parola corretta in base all'immagine visualizzata e all'audio che senti. Inoltre, prova a ripetere la parola che hai sentito."])},
    "See, hear & select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedere, ascoltare e selezionare il vocabolario"])},
    "Write the word that can be seen in the picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scrivi la parola che può essere vista nell'immagine e ascoltata nell'audio nella casella di testo fornita. Inoltre, prova a ripetere la parola che hai sentito."])},
    "License:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licenza"])},
    "Choose vocabulary based on image displayed.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli il vocabolario corretto in base all'immagine visualizzata."])},
    "See picture & select vocabulary:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guarda l'immagine e seleziona il vocabolario"])},
    "Check answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlla le risposte"])},
    "write vocabulary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["scrivere il vocabolario"])},
    "Write translation text box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scrivi la traduzione corretta della parola nella casella di testo fornita."])},
    "Which units learn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quali unità vorresti imparare?"])},
    "Lehrer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insegnante"])},
    "Create course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea corso"])},
    "course name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del corso"])},
    "Description:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
    "Source language:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linguaggio del codice"])},
    "Add Lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi lezione"])},
    "Create Lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea lezione"])},
    "Vocabulary list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista di vocaboli*"])},
    "*optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*opzionale"])},
    "Apply template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicare il modello"])},
    "insert lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inserire lezioni"])},
    "lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lezioni"])},
    "course members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membri del corso"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiche"])},
    "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelli"])},
    "No course members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non ci sono membri del corso"])},
    "Choose a file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli un file"])},
    "Upload vocabulary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica il vocabolario"])},
    "Delete Courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina corso/i"])},
    "Which courses delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quali corsi vuoi eliminare?"])},
    "confirm selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confermare la selezione"])},
    "delete lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eliminare le lezioni"])},
    "Which lessons delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quali lezioni vuoi eliminare?"])},
    "select class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona la classe"])},
    "select students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona gli studenti"])},
    "Add student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi studente"])},
    "course member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["membro del corso"])},
    "Organisator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizzatore"])},
    "change class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia classe"])},
    "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["studenti"])},
    "New name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo nome"])},
    "change name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cambia nome"])},
    "NoLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun accesso"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Squadra"])},
    "Common": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comune"])},
    "add a picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi un'immagine"])},
    "login with school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi con l'account della scuola"])},
    "page not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa pagina non è stata trovata"])},
    "back to home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ritorna alla Homepage"])},
    "back to login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna al login"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a partire dal"])},
    "no learn music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo browser non supporta l'apprendimento della musica"])},
    "delete music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina la musica"])},
    "new class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea nuova classe"])},
    "delete class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina classe"])},
    "name of new class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome della nuova classe"])},
    "remove student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovi studente"])},
    "no students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non ci sono studenti in questa classe"])},
    "show warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra avvisi"])},
    "export new passwords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esporta nuove password"])},
    "migrate user ad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migrazione di utenti e gruppi da Microsoft Active Directory"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizio"])},
    "training mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modalità di allenamento"])},
    "no vocab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è disponibile alcun vocabolario"])},
    "enable editing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permettere la modifica"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
    "discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scartare"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "no book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non ci sono libri disponibili"])},
    "save changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvare le modifiche"])},
    "csv files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File CSV"])},
    "name of lecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome della conferenza"])},
    "generate new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genera nuova password"])},
    "reset values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimposta i valori"])},
    "delete user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina utente"])},
    "no desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo corso non ha descrizione"])},
    "del lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina lezione"])},
    "hide lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nascondi lezione"])},
    "show lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra lezione"])},
    "edit lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica lezione"])},
    "no lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non ci sono lezioni disponibili!"])},
    "discard changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non salvare le modifiche"])},
    "pic desc and license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione dell'immagine/Licenza"])},
    "enter license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci la licenza nel campo"])},
    "add student to class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi studente alla classe"])},
    "Class of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classe di"])},
    "class name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome della classe"])},
    "See, hear & write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedere, ascoltare e scrivere il vocabolario"])},
    "See pic & write vocab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guarda l'immagine e scrivi il vocabolario"])},
    "write word from picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scrivi la parola che vedi sullo schermo nel campo di testo fornito."])},
    "check answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlla la risposta"])},
    "diabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabilitato"])},
    "save settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva impostazioni"])},
    "no students course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non ci sono studenti in questo corso!"])},
    "no statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non ci sono statistiche disponibili per questo corso!"])},
    "no templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non ci sono modelli per questa combinazione linguistica!"])},
    "change course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia rotta"])},
    "del user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina utente"])},
    "add member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi membro"])},
    "add members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi membri"])},
    "add whole class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile aggiungere un'intera classe non selezionando uno studente!"])},
    "information1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo scopo di questa tesi di diploma è quello di sviluppare una piattaforma di apprendimento online che consenta agli studenti di apprendere in modo più efficiente mantenendo il più basso possibile lo sforzo organizzativo per gli insegnanti. Il modo più tradizionale per imparare il vocabolario è usare un vocabolario. Questo metodo richiede poco sforzo per l'insegnante, ma l'apprendimento è molto noioso e inefficiente."])},
    "information2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il più grande vantaggio di un trainer online rispetto al libro di vocaboli è il modo in cui viene appreso. La piattaforma consente l'uso di un'ampia varietà di metodi di apprendimento, tenendo conto dei diversi tipi di studenti. Gli studenti possono imparare il vocabolario dai loro libri di testo in qualsiasi momento, perché l'insegnante fornisce agli studenti il vocabolario in elenchi predefiniti e può monitorare continuamente i progressi di apprendimento degli studenti."])},
    "information3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con l'aiuto di un algoritmo di apprendimento che calcola in background quale vocabolario viene selezionato, è possibile una formazione individuale su misura per l'utente. L'algoritmo dà la priorità al vocabolario che l'utente non ha ancora padroneggiato così bene e lo controlla di conseguenza più spesso."])},
    "information4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gli studenti uditivi, in particolare, possono trarre grandi benefici dall'ascoltare la musica durante l'apprendimento. Il cervello impara in modo molto più efficace quando viene stimolato dal rumore durante l'apprendimento, ma questo vale solo per un rumore di fondo uniforme ed equilibrato. Questo crea un'atmosfera lavorativa che favorisce la concentrazione senza causare distrazioni. Ecco perché SZ-Wordly offre anche musica per il Superlearning. Sono fornite quattro tracce"])},
    "our idea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La nostra idea"])},
    "no superlearning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è disponibile musica per il Superlearning."])},
    "no cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senza copertura"])},
    "book name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del libro"])},
    "publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome dell'editore"])},
    "add csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi file CSV"])},
    "Benutzerverwaltung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione utenti"])},
    "Klassenverwaltung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione della classe"])},
    "Kursübersicht": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panoramica del corso"])},
    "Meine Kurse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I miei corsi"])},
    "Büchervorlagen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelli di libri"])},
    "Schulverwaltung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direzione Scolastica"])},
    "Audioverwaltung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione audio"])},
    "Bücherverwaltung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione del libro"])},
    "Kursdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli del Corso"])},
    "Lektionsdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli della lezione"])},
    "Lernen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apprendimento"])},
    "Buchdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli del libro"])},
    "Projektleiter, Frontend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsabile di progetto, front-end"])},
    "Frontend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fine frontale"])},
    "Backend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend"])},
    "music1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barocco strumentale - Brano"])},
    "music2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classico strumentale - Traccia"])},
    "music3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musica ambient - Traccia"])},
    "music4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suoni della natura / Rumore ambientale - Traccia"])},
    "del music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi davvero eliminare la musica?"])},
    "no return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa azione non può essere annullata!"])},
    "no changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna password è stata modificata!"])},
    "edit school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Scuola"])},
    "del student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina studente dalla classe"])},
    "del school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi davvero eliminare questa scuola?"])},
    "school info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sulla scuola"])},
    "you have": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai"])},
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["di"])},
    "exercises solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["esercizi risolti senza errori..."])},
    "wrong translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sfortunatamente questo è sbagliato, la traduzione corretta di"])},
    "is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è"])},
    "password matching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La nuova password deve corrispondere!"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazione!"])},
    "confirm del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina"])},
    "session timeout text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La sessione è scaduta. Devi accedere di nuovo!"])},
    "session timeout title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La sessione è scaduta"])},
    "not log in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sei loggato"])},
    "log in missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun accesso"])},
    "Seite nicht gefunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pagina non trovata"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e"])},
    "no book change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il libro non può essere cambiato."])},
    "no book del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile eliminare il libro."])},
    "no book create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile creare il libro."])},
    "no book del full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stato possibile eliminare completamente i libri."])},
    "no book load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile caricare il libro."])},
    "no books load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stato possibile caricare i libri."])},
    "no class add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile aggiungere la classe."])},
    "no classes add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile aggiungere le classi."])},
    "no class load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile caricare la classe ."])},
    "no classes load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile caricare le classi."])},
    "error class load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante la rimozione della classe selezionata."])},
    "error classes load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante la rimozione delle classi selezionate."])},
    "no class change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stato possibile modificare la classe."])},
    "fill all fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di compilare tutti i campi!"])},
    "no data load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stato possibile caricare i dati."])},
    "file to big": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I file caricati sono troppo grandi! Dimensione massima 40 MiB!"])},
    "no course load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile caricare il corso."])},
    "no course add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile aggiungere il corso."])},
    "course error del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante l'eliminazione dei corsi selezionati."])},
    "course error change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante il cambio di rotta."])},
    "try again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore riprova!"])},
    "no lesson add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile aggiungere la lezione."])},
    "no lessons load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile caricare le lezioni."])},
    "no lesson load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile caricare la lezione ."])},
    "error del lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante l'eliminazione delle lezioni selezionate."])},
    "no lesson change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La lezione non può essere cambiata."])},
    "no statistic load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile caricare le statistiche."])},
    "template add course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il modello è stato aggiunto al corso"])},
    "error add template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore durante l'aggiunta del modello."])},
    "no organizer add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile aggiungere l'organizzatore."])},
    "no organizers add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile aggiungere gli organizzatori."])},
    "no organizer load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile caricare l'organizzatore."])},
    "no organizers load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile caricare gli organizzatori."])},
    "error del organizer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante la rimozione dell'organizzatore selezionato."])},
    "error del organizers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante la rimozione degli organizzatori selezionati."])},
    "no organizer change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile modificare l'organizzatore."])},
    "no organizers change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gli organizzatori non possono essere cambiati."])},
    "no school add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile aggiungere la scuola."])},
    "no school load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La scuola non può essere caricata."])},
    "no schools load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le scuole non potevano essere caricate."])},
    "error del school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante la rimozione della scuola selezionata."])},
    "error school change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La scuola non può essere cambiata."])},
    "request a minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa richiesta potrebbe richiedere fino a un minuto, si prega di pazientare!"])},
    "settings saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le impostazioni sono state salvate."])},
    "error save settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore durante il salvataggio delle impostazioni."])},
    "error load settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore durante il caricamento delle impostazioni."])},
    "error load statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile caricare le statistiche."])},
    "no student add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stato possibile aggiungere lo studente."])},
    "no students add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stato possibile aggiungere gli studenti."])},
    "no student load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stato possibile caricare lo studente."])},
    "no students load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stato possibile caricare gli studenti."])},
    "error del student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante la rimozione dello studente selezionato."])},
    "error del students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante la rimozione degli studenti selezionati."])},
    "no student change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo studente non può essere cambiato."])},
    "no students class change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stato possibile modificare la classe dello studente."])},
    "no students change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gli studenti non potevano essere cambiati."])},
    "no start training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stato possibile avviare la modalità allenamento."])},
    "no del audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stato possibile eliminare l'audio."])},
    "no upload audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stato possibile caricare l'audio."])},
    "no load audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile caricare i file audio."])},
    "no teacher add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile aggiungere l'insegnante."])},
    "no teachers add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stato possibile aggiungere gli insegnanti."])},
    "no teacher load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile caricare l'insegnante."])},
    "no teachers load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stato possibile caricare gli insegnanti."])},
    "error del teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante la rimozione dell'insegnante selezionato."])},
    "error del teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante la rimozione degli insegnanti selezionati."])},
    "no change teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'insegnante non può essere cambiato."])},
    "no change teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'insegnante non può essere cambiato."])},
    "no add vocab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile aggiungere il vocabolario alla lezione."])},
    "no upload vocab image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stato possibile caricare l'immagine del vocabolario."])},
    "fail signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnessione non riuscita."])},
    "invalid user/pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username o password non validi!"])},
    "error ms login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante l'accesso a Microsoft!"])},
    "no password change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stato possibile modificare la password!"])},
    "password changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password è stata cambiata con successo!"])},
    "valid email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci una e-mail valida"])},
    "del user?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi davvero eliminare l'utente?"])}
  }
}