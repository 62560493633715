
import TheLanguageSwitchDropdown from '@/components/common/layout/TheLanguageSwitchDropdown.vue';
import TheUserProfile from '@/components/common/layout/TheUserProfile.vue';
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'locale-changer',
  data () {
    return {
      langs: ['Deutsch', 'English'],
      flags: ["@/assets/img/country_flags/germany.svg"]
    };
  },
  components: {
    TheUserProfile, TheLanguageSwitchDropdown
  },
  props: {
    title: String
  },
  computed: {
    ...mapState({ loggedIn: 'loggedIn' })
  }
});
