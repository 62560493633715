import Swal, { customClass } from '@/sweetalert2/index';
import { SweetAlertResult } from 'sweetalert2';
import i18n from '@/i18n';

export function showSuccess (text: string): Promise<SweetAlertResult> {
  return Swal.fire({ title: i18n.global.t('messages.success'), text, icon: 'success' });
}

export function showError (text: string): Promise<SweetAlertResult> {
  return Swal.fire({ title: i18n.global.t('messages.mistake'), text, icon: 'error' });
}

export function showInfo (text: string): Promise<SweetAlertResult> {
  return Swal.fire({ title: i18n.global.t('messages.info'), text, icon: 'info' });
}

export function confirmDelete (title: string, text: string): Promise<SweetAlertResult> {
  return Swal.fire({
    titleText: title,
    text,
    icon: 'question',
    showCancelButton: true,
    cancelButtonText: i18n.global.t('messages.Cancel'),
    confirmButtonText: i18n.global.t('messages.confirm del')
  });
}

export function showSessionTimeout (): Promise<SweetAlertResult> {
  return Swal.fire({
    icon: 'warning',
    text: i18n.global.t('messages.session timeout text'),
    title: i18n.global.t('messages.session timeout title')
  });
}

export function showWrongWordToast (text: string, target: HTMLElement): Promise<SweetAlertResult> {
  return Swal.fire({
    toast: true,
    position: 'bottom',
    text,
    icon: 'error',
    timer: 10000,
    target,
    customClass: { ...customClass, container: 'position-relative' }
  });
}
