import { errorPrepend, LanguageShort, User } from '@/models';
import axios from 'axios';
import i18n from '@/i18n';

export class Course {
  static LOAD_ERROR = i18n.global.t('messages.no course load') + errorPrepend();
  static ADD_ERROR = i18n.global.t('messages.no course add') + errorPrepend();
  static DELETE_MULTIPLE_ERROR = i18n.global.t('messages.course error del') + errorPrepend();
  static UPDATE_ERROR = i18n.global.t('messages.course error change') + errorPrepend();

  constructor (
    public id: number,
    public name: string,
    public languageFrom: LanguageShort,
    public languageTo: LanguageShort,
    public teacher?: string,
    public description?: string,
    public studentIds?: number[],
    public classIds?: number[]
  ) {
  }

  static async getAll (): Promise<Course[]> {
    const { data } = await axios.get('api/course/all');
    return data;
  }

  static async update (course: Course): Promise<void> {
    const { data } = await axios.put('api/course', {
      courseId: course.id,
      name: course.name,
      description: course.description
    });
    return data;
  }

  static async getById (courseId: number): Promise<Course> {
    const { data } = await axios.get(`api/course/${courseId}`);
    return data;
  }

  static async create (course: Course): Promise<{id: number}> {
    const { data } = await axios.post('api/course', course);
    return data;
  }

  static async addLesson (course: Course, lessonName: string): Promise<{id: number}> {
    const { data } = await axios.post('api/course/lesson', {
      name: lessonName,
      courseId: course.id,
      languageFrom: course.languageFrom,
      languageTo: course.languageTo
    });
    return data;
  }

  static async delete (courseId: number): Promise<void> {
    await axios.delete(`api/course/${courseId}`);
  }

  static async removeMember (members: Array<User>, course: number): Promise<void> {
    const { data } = await axios.put('api/course/students', {
      courseId: course,
      studentIds: members.map(m => m.id),
      classIds: []
    });
    return data;
  }

  static async addMembers (courseId: number, members: Array<number>, classes: Array<number>): Promise<void> {
    const { data } = await axios.put('api/course/students', {
      courseId: courseId,
      studentIds: members,
      classIds: classes
    });
    return data;
  }

  static async getCourseMembers (courseId: number): Promise<User[]> {
    const { data } = await axios.get(`api/course/${courseId}/member/all`);
    return data;
  }
}
