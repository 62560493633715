import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store, { key } from './store';
import axios from 'axios';
import { backendUrl, User } from '@/models';
import { msalInstance } from '@/msal/authConfig.ts';
import { showError } from '@/sweetalert2/templates';
import i18n from './i18n';

const devMode = process.env.NODE_ENV === 'development';

axios.defaults.withCredentials = devMode;
axios.defaults.baseURL = backendUrl;

msalInstance.handleRedirectPromise().then(() => {
  const accounts = msalInstance.getAllAccounts();
  return accounts.length > 0;
}).catch(async () => {
  await showError(User.LOGIN_AD_FAILED);
  return false;
});

const app = createApp(App).use(i18n);
app.use(store, key)
  .use(router);

app.directive('focus', {
  mounted (el) {
    el.focus();
  }
});

app.mount('#app');
