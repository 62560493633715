
import SideNavItem from '@/components/common/layout/SideNavItem.vue';
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import TheUserProfile from '@/components/common/layout/TheUserProfile.vue';

export default defineComponent({
  components: { TheUserProfile, SideNavItem },
  computed: {
    ...mapState({
      courses: 'courses',
      sideNavVisible: 'sideNavVisible',
      loggedIn: 'loggedIn',
      user: 'user'
    })
  }
});
