import { Language, LanguageShort, UserType } from '@/models';
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

export const languageMixin = defineComponent({
  methods: {
    userTypeToGerman (type: UserType): string {
      switch (type) {
        case 'student':
          return 'Schüler';
        case 'teacher':
          return 'Lehrer';
        case 'organizer':
          return 'Organisator';
        case 'administrator':
          return 'Administrator';
        default:
          return 'Fehler';
      }
    },
    languageToGerman (language: LanguageShort): string {
      if (language === undefined || this.languages === undefined || this.languages.length === 0) {
        return '';
      }
      return this.languages.find((l: Language) => l.name === language).fullname;
    },
    shortToFull (language?: LanguageShort): Language | undefined {
      if (language === undefined || this.languages === undefined || this.languages.length === 0) {
        return undefined;
      }
      return this.languages.find((l: Language) => l.name === language);
    }
  },
  computed: {
    ...mapState({ languages: 'languages' })
  }
});
