import { errorPrepend, LanguageShort } from '@/models';
import axios from 'axios';
import i18n from '@/i18n';

export type UserType = 'student' | 'teacher' | 'organizer' | 'administrator';

export class User {
  static LOGOUT_FAILED = i18n.global.t('messages.fail signout') + errorPrepend();
  static LOGIN_FAILED = i18n.global.t('messages.invalid user/pass');
  static LOGIN_AD_FAILED = i18n.global.t('messages.error ms login') + errorPrepend();
  static PASSWORD_UPDATE_FAILED = i18n.global.t('messages.no password change') + errorPrepend();
  static PASSWORD_UPDATE_SUCCESS = i18n.global.t('messages.password changed');
  constructor (
    public id: number,
    public firstname: string,
    public lastname: string,
    public email: string,
    public type: UserType,
    public preferredLang: LanguageShort) {
  }

  static getLandingPage (type: UserType):string {
    return <string>User.switchTypes(type, '/dashboard/', '/dashboard/', '/users/', '/books/');
  }

  static switchTypes (type: UserType, studentVal: unknown, teacherVal: unknown, organizerVal: unknown, adminVal: unknown): unknown {
    switch (type) {
      case 'student':
        return studentVal;
      case 'teacher':
        return teacherVal;
      case 'organizer':
        return organizerVal;
      case 'administrator':
        return adminVal;
      default:
        return '';
    }
  }

  static async deleteSession (): Promise<void> {
    const { data } = await axios.delete('api/logout');
    return data;
  }

  static async createSession (loginData: unknown): Promise<{ usertype: string }> {
    const { data } = await axios.post('api/login', loginData);
    return data;
  }

  static async loginAd (jwt: string): Promise<{ usertype: UserType }> {
    const { data } = await axios.post('api/loginAd', { jwt });
    return data;
  }

  static async updatePassword (passwords: { old: string, new1: string, new2: string }): Promise<void> {
    const { data } = await axios.put('api/user/password', {
      oldPassword: passwords.old,
      newPassword: passwords.new1
    });
    return data;
  }

  static async getUser (): Promise<User> {
    const { data } = await axios.get('api/user');
    return data;
  }
}

export class EditableUser {
  constructor (
    public id: number,
    public firstname: string,
    public lastname: string,
    public email: string,
    public password?: string) {
  }
}
