export default {
  "messages": {
    "Anmeldung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enregistrement"])},
    "SZ Wordly registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Inscription SZ Wordly"])},
    "Welcome back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Content de te revoir"])},
    "or with local user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ou avec un utilisateur local"])},
    "SZ-Wordly - vocabulary trainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" SZ-Wordly - Entraîneur de vocabulaire"])},
    "save email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Se souvenir de l'e-mail"])},
    "E-mail / username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" E-mail / nom d'utilisateur"])},
    "Sign-Out from SZ Wordly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Déconnexion de SZ Wordly"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Succès!"])},
    "Successfully logged off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Déconnexion réussie !"])},
    "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" D'ACCORD"])},
    "Sign in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" S'identifier"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Mot de passe"])},
    "Administrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Administrateur"])},
    "Delete?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Supprimer?"])},
    "Delete book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Faut-il vraiment supprimer le livre ?"])},
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" menu"])},
    "source language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Langue originelle"])},
    "target language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Langue cible"])},
    "Publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Éditeur"])},
    "Startseite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Page d'accueil"])},
    "book templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" modèles de livre"])},
    "school administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" administration scolaire"])},
    "audio management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" gestion audio"])},
    "Einstellungen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Paramètres"])},
    "books management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" gestion des livres"])},
    "Available Books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Livres disponibles"])},
    "Create new book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Créer un nouveau livre"])},
    "Surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Nom de famille"])},
    "Extinguish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Supprimer"])},
    "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Contacter"])},
    "Change Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Changer le mot de passe"])},
    "old password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Ancien mot de passe"])},
    "New Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Nouveau mot de passe"])},
    "Repeat new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Répété le nouveau mot de passe"])},
    "password length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Longueur du mot de passe"])},
    "Show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Spectacle"])},
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Éditer"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Supprimer"])},
    "first name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Prénom"])},
    "e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" E-mail"])},
    "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Actions"])},
    "Create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Créer"])},
    "search term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Terme de recherche"])},
    "Create new school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Créer une nouvelle école"])},
    "Create school button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Créer un bouton d'école"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Adresse"])},
    "create school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" créer une école"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "Add new superlearning music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Ajouter une nouvelle musique de superapprentissage"])},
    "music name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Nom de la musique"])},
    "name of the new music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" nom de la nouvelle musique"])},
    "Add to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Ajouter à"])},
    "Existing learning music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Musique d'apprentissage existante"])},
    "Access failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" L'accès a échoué"])},
    "mistake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Erreur!"])},
    "You do not have access to this resource!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Vous n'avez pas accès à cette ressource !"])},
    "Sign out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Déconnexion"])},
    "Schüler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Étudiant"])},
    "your profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Votre profil"])},
    "E-mail (username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" E-mail (nom d'utilisateur"])},
    "voice output": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" sortie vocale"])},
    "superlearning music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" superapprentissage de la musique"])},
    "Voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Voix"])},
    "masculine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" masculin"])},
    "Feminine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Féminin"])},
    "Number of vocabulary per learning unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Nombre de vocabulaire par unité d'apprentissage"])},
    "my courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Mes cours"])},
    "There are no courses available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Il n'y a pas de cours disponibles"])},
    "Sign out of school account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Se déconnecter du compte scolaire"])},
    "start training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Commence l'entraînement"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" la description"])},
    "Select vocabulary:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Sélectionnez le vocabulaire"])},
    "three choices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Choisissez la traduction correcte du mot parmi les trois choix proposés."])},
    "Form vocabulary pairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Formez des paires de vocabulaire en cliquant sur la langue source et la langue cible du mot."])},
    "training completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Formation terminée !"])},
    "You have completed x of x": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Vous avez terminé x exercices sur x correctement sans erreur..."])},
    "Repeat Lecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Répéter la conférence"])},
    "Back to the lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Retour à la leçon"])},
    "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Progrès"])},
    "No picture available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Aucune image disponible"])},
    "form vocabulary pairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" former des paires de vocabulaire"])},
    "Choose word based image displayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le mot correct en fonction de l'image affichée et de l'audio que vous entendez. Essayez également de répéter le mot que vous avez entendu."])},
    "See, hear & select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Voir, entendre et sélectionner le vocabulaire"])},
    "Write the word that can be seen in the picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Écrivez le mot qui peut être vu dans l'image et entendu dans l'audio dans la zone de texte fournie. Essayez également de répéter le mot que vous avez entendu."])},
    "License:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Licence"])},
    "Choose vocabulary based on image displayed.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Choisissez le vocabulaire correct en fonction de l'image affichée."])},
    "See picture & select vocabulary:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Voir l'image et sélectionner le vocabulaire"])},
    "Check answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Vérifier les réponses"])},
    "write vocabulary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" écrire du vocabulaire"])},
    "Write translation text box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Écrivez la traduction correcte du mot dans la zone de texte fournie."])},
    "Which units learn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Quelles unités aimeriez-vous apprendre ?"])},
    "Lehrer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Prof"])},
    "Create course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Créer un cours"])},
    "course name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Nom du cours"])},
    "Description:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" La description"])},
    "Source language:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Langue originelle"])},
    "Add Lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Ajouter une leçon"])},
    "Create Lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Créer une leçon"])},
    "Vocabulary list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Liste de vocabulaire*"])},
    "*optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" *optionnel"])},
    "Apply template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Appliquer le modèle"])},
    "insert lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" insérer des leçons"])},
    "lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Cours"])},
    "course members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Membres du cours"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Statistiques"])},
    "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Modèles"])},
    "No course members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Il n'y a pas de membres de cours"])},
    "Choose a file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Choisissez un fichier"])},
    "Upload vocabulary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Télécharger le vocabulaire"])},
    "Delete Courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Supprimer des cours"])},
    "Which courses delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quels cours souhaitez-vous supprimer ?"])},
    "confirm selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" confirmer la sélection"])},
    "delete lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" supprimer des leçons"])},
    "Which lessons delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Quelles leçons voulez-vous supprimer ?"])},
    "select class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Sélectionnez la classe"])},
    "select students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Sélectionnez les étudiants"])},
    "Add student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Ajouter un étudiant"])},
    "course member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" membre du cours"])},
    "Organisator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Organisateur"])},
    "change class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Changer de classe"])},
    "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" étudiants"])},
    "New name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Nouveau nom"])},
    "change name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" changer de nom"])},
    "NoLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Pas de connexion"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Équipe"])},
    "Common": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Commun"])},
    "add a picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Ajouter une image"])},
    "login with school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Connectez-vous avec le compte de l'école"])},
    "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Erreur 404"])},
    "page not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Cette page est introuvable"])},
    "back to home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Retour à la page d'accueil"])},
    "back to login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Retour connexion"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" à partir de"])},
    "no learn music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Votre navigateur ne prend pas en charge l'apprentissage de la musique"])},
    "delete music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Supprimer la musique"])},
    "new class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Créer une nouvelle classe"])},
    "delete class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Supprimer la classe"])},
    "name of new class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Nom de la nouvelle classe"])},
    "remove student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Supprimer l'élève"])},
    "no students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Il n'y a pas d'élèves dans cette classe"])},
    "show warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Afficher les avertissements"])},
    "export new passwords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Exporter de nouveaux mots de passe"])},
    "migrate user ad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Migrer des utilisateurs et des groupes depuis Microsoft Active Directory"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Début"])},
    "training mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" mode entraînement"])},
    "no vocab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Il n'y a pas de vocabulaire disponible"])},
    "enable editing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Activer la modification"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Sauver"])},
    "discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Jeter"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Ändern"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Nom"])},
    "no book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a pas de livres disponibles"])},
    "save changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Sauvegarder les modifications"])},
    "csv files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Fichier(s) CSV"])},
    "name of lecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Nom de la conférence"])},
    "generate new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Générer un nouveau mot de passe"])},
    "reset values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Réinitialiser les valeurs"])},
    "delete user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Supprimer l'utilisateur"])},
    "no desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Ce cours n'a pas de description"])},
    "del lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Supprimer le cours"])},
    "hide lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Masquer la leçon"])},
    "show lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Afficher la leçon"])},
    "edit lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Modifier la leçon"])},
    "no lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Il n'y a pas de cours disponibles !"])},
    "discard changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Annuler les modifications"])},
    "pic desc and license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Description de l'image/Licence"])},
    "enter license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Entrez la licence dans le champ"])},
    "add student to class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Ajouter un élève à la classe"])},
    "Class of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Une sorte de"])},
    "class name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Nom du cours"])},
    "See, hear & write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Voir, entendre et écrire du vocabulaire"])},
    "See pic & write vocab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Voir l'image et écrire le vocabulaire"])},
    "write word from picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Écrivez le mot que vous voyez à l'écran dans le champ de texte fourni."])},
    "check answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Vérifier la réponse"])},
    "diabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" désactivé"])},
    "save settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Enregistrer les paramètres"])},
    "no students course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Il n'y a pas d'étudiants dans ce cours !"])},
    "no statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Il n'y a pas de statistiques disponibles pour ce cours !"])},
    "no templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Il n'y a pas de modèles pour cette combinaison de langues !"])},
    "change course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Changer de cours"])},
    "del user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Supprimer l'utilisateur"])},
    "add member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Ajouter un membre"])},
    "add members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Ajouter des membres"])},
    "add whole class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Une classe entière peut être ajoutée en ne sélectionnant pas d'élève !"])},
    "information1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'objectif de ce mémoire est de développer une plateforme d'apprentissage en ligne permettant aux étudiants d'apprendre plus efficacement tout en limitant au maximum l'effort d'organisation des enseignants. La façon la plus traditionnelle d'apprendre le vocabulaire est d'utiliser un livre de vocabulaire. Cette méthode demande peu d'efforts à l'enseignant, mais l'apprentissage est très fastidieux et inefficace."])},
    "information2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Le plus grand avantage d'un entraîneur en ligne par rapport au livre de vocabulaire est la façon dont il est appris. La plateforme permet l'utilisation d'une grande variété de méthodes d'apprentissage, en tenant compte des différents types d'apprenants. Les étudiants peuvent apprendre le vocabulaire de leurs manuels à tout moment, car l'enseignant fournit aux étudiants le vocabulaire dans des listes prédéfinies et peut suivre en permanence les progrès d'apprentissage des étudiants."])},
    "information3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À l'aide d'un algorithme d'apprentissage qui calcule en arrière-plan le vocabulaire sélectionné, un entraînement individuel est rendu possible, spécifiquement adapté à l'utilisateur. L'algorithme donne la priorité au vocabulaire que l'utilisateur ne maîtrise pas encore si bien et le vérifie en conséquence plus souvent."])},
    "information4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Les apprenants auditifs en particulier peuvent grandement bénéficier de l'écoute de la musique tout en apprenant. Le cerveau apprend beaucoup plus efficacement lorsqu'il est stimulé par le bruit pendant l'apprentissage, mais cela ne s'applique qu'à un bruit de fond régulier et équilibré. Cela crée une atmosphère de travail qui favorise la concentration sans causer de distractions. C'est pourquoi SZ-Wordly propose également de la musique super-apprenante. Quatre pistes sont fournies"])},
    "our idea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Notre idée"])},
    "no superlearning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Il n'y a pas de musique de superapprentissage disponible."])},
    "no cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Pas de couverture"])},
    "book name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Nom du livre"])},
    "publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Nom de l'éditeur"])},
    "add csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Ajouter un fichier CSV"])},
    "Benutzerverwaltung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Gestion des utilisateurs"])},
    "Klassenverwaltung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Gestion de classe"])},
    "Kursübersicht": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Aperçu du cours"])},
    "Meine Kurse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Mes cours"])},
    "Büchervorlagen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Modèles de livre"])},
    "Schulverwaltung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Gestion scolaire"])},
    "Audioverwaltung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion audio"])},
    "Bücherverwaltung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Gestion des livres"])},
    "Kursdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Détails du cours"])},
    "Lektionsdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Détails de la conférence"])},
    "Lernen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Apprentissage"])},
    "Buchdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Détails du livre"])},
    "Projektleiter, Frontend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Chef de projet, Frontend"])},
    "Frontend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" L'extrémité avant"])},
    "Backend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Backend"])},
    "music1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Baroque instrumental - Piste"])},
    "music2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Classique instrumental - Piste"])},
    "music3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Musique d'ambiance - Piste"])},
    "music4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Sons de la nature / Bruit ambiant - Piste"])},
    "del music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Voulez-vous vraiment supprimer la musique ?"])},
    "no return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Cette action est irréversible !"])},
    "no changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Aucun mot de passe n'a été modifié !"])},
    "edit school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Modifier l'école"])},
    "del student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Supprimer un élève de la classe"])},
    "del school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Voulez-vous vraiment supprimer cette école ?"])},
    "school info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Informations sur l'école"])},
    "you have": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Vous avez"])},
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" de"])},
    "exercises solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" exercices résolus sans erreur..."])},
    "wrong translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Malheureusement, c'est faux, la traduction correcte de"])},
    "is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" est"])},
    "password matching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Le nouveau mot de passe doit correspondre !"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Informations!"])},
    "confirm del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Supprimer"])},
    "session timeout text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" La session a expiré. Vous devez vous reconnecter !"])},
    "session timeout title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" session expirée"])},
    "not log in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Vous n'êtes pas connecté"])},
    "log in missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Pas de connexion"])},
    "Seite nicht gefunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Page non trouvée"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" et"])},
    "no book change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Le livre ne pouvait pas être changé."])},
    "no book del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le livre n'a pas pu être supprimé."])},
    "no book create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Impossible de créer le livre."])},
    "no book del full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Les livres n'ont pas pu être complètement supprimés."])},
    "no book load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Le livre n'a pas pu être chargé."])},
    "no books load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Les livres n'ont pas pu être chargés."])},
    "no class add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" La classe n'a pas pu être ajoutée."])},
    "no classes add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Les classes n'ont pas pu être ajoutées."])},
    "no class load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" La classe n'a pas pu être chargée."])},
    "no classes load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Les classes n'ont pas pu être chargées."])},
    "error class load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Une erreur s'est produite lors de la suppression de la classe sélectionnée."])},
    "error classes load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Une erreur s'est produite lors de la suppression des classes sélectionnées."])},
    "no class change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" La classe n'a pas pu être modifiée."])},
    "fill all fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Merci de remplir tous les champs!"])},
    "no data load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Les données n'ont pas pu être chargées."])},
    "file to big": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Le(s) fichier(s) téléchargé(s) sont trop volumineux ! Taille maximale 40 Mio !"])},
    "no course load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Le cours n'a pas pu être chargé."])},
    "no course add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Impossible d'ajouter le cours."])},
    "course error del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Une erreur s'est produite lors de la suppression des cours sélectionnés."])},
    "course error change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Une erreur s'est produite lors du changement de cap."])},
    "try again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Veuillez réessayer!"])},
    "no lesson add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" La leçon n'a pas pu être ajoutée."])},
    "no lessons load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Les leçons n'ont pas pu être chargées."])},
    "no lesson load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" La leçon n'a pas pu être chargée."])},
    "error del lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite lors de la suppression des leçons sélectionnées."])},
    "no lesson change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" La leçon ne pouvait pas être modifiée."])},
    "no statistic load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Les statistiques n'ont pas pu être chargées."])},
    "template add course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Le modèle a été ajouté au cours"])},
    "error add template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Erreur lors de l'ajout du modèle."])},
    "no organizer add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" L'organisateur n'a pas pu être ajouté."])},
    "no organizers add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Les organisateurs n'ont pas pu être ajoutés."])},
    "no organizer load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" L'organisateur n'a pas pu être chargé."])},
    "no organizers load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Les organisateurs n'ont pas pu être chargés."])},
    "error del organizer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Une erreur s'est produite lors de la suppression de l'organisateur sélectionné."])},
    "error del organizers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Une erreur s'est produite lors de la suppression des organisateurs sélectionnés."])},
    "no organizer change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" L'organisateur n'a pas pu être changé."])},
    "no organizers change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Les organisateurs ne pouvaient pas être changés."])},
    "no school add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" L'école n'a pas pu être ajoutée."])},
    "no school load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" L'école n'a pas pu être chargée."])},
    "no schools load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Les écoles n'ont pas pu être chargées."])},
    "error del school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Une erreur s'est produite lors de la suppression de l'école sélectionnée."])},
    "error school change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" L'école ne pouvait pas être changée."])},
    "request a minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Cette demande peut prendre jusqu'à une minute, soyez patient !"])},
    "settings saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Les paramètres ont été enregistrés."])},
    "error save settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Erreur lors de l'enregistrement des paramètres."])},
    "error load settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Erreur lors du chargement des paramètres."])},
    "error load statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Les statistiques n'ont pas pu être chargées."])},
    "no student add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'élève n'a pas pu être ajouté."])},
    "no students add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Les élèves n'ont pas pu être ajoutés."])},
    "no student load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" L'élève n'a pas pu être chargé."])},
    "no students load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Les élèves n'ont pas pu être chargés."])},
    "error del student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Une erreur s'est produite lors de la suppression de l'élève sélectionné."])},
    "error del students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Une erreur s'est produite lors de la suppression des élèves sélectionnés."])},
    "no student change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" L'élève n'a pas pu être changé."])},
    "no students class change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" La classe de l'élève n'a pas pu être modifiée."])},
    "no students change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Les élèves ne pouvaient pas être changés."])},
    "no start training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Le mode d'entraînement n'a pas pu être démarré."])},
    "no del audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" L'audio n'a pas pu être supprimé."])},
    "no upload audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" L'audio n'a pas pu être téléchargé."])},
    "no load audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Les fichiers audio n'ont pas pu être chargés."])},
    "no teacher add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" L'enseignant n'a pas pu être ajouté."])},
    "no teachers add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Les enseignants n'ont pas pu être ajoutés."])},
    "no teacher load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Le professeur n'a pas pu être chargé."])},
    "no teachers load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Les professeurs n'ont pas pu être chargés."])},
    "error del teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Une erreur s'est produite lors de la suppression de l'enseignant sélectionné."])},
    "error del teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Une erreur s'est produite lors de la suppression des enseignants sélectionnés."])},
    "no change teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Le professeur ne pouvait pas être changé."])},
    "no change teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Le professeur ne pouvait pas être changé."])},
    "no add vocab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Le vocabulaire n'a pas pu être ajouté à la leçon."])},
    "no upload vocab image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'image de vocabulaire n'a pas pu être téléchargée."])},
    "fail signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" La déconnexion a échoué."])},
    "invalid user/pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Nom d'utilisateur ou mot de passe invalide !"])},
    "error ms login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Une erreur s'est produite lors de la connexion Microsoft !"])},
    "no password change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Le mot de passe n'a pas pu être modifié !"])},
    "password changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Le mot de passe a été modifié avec succès !"])},
    "valid email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Entrer un email valide"])},
    "del user?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Voulez-vous vraiment supprimer l'utilisateur ?"])}
  }
}