import axios from 'axios';
import { EditableUser } from '@/models/User';
import { errorPrepend } from '@/models/index';
import i18n from '@/i18n';

export class Student extends EditableUser {
  static ADD_ERROR = i18n.global.t('messages.no student add') + errorPrepend();
  static ADD_MULTIPLE_ERROR = i18n.global.t('messages.no students add') + errorPrepend();
  static LOAD_ERROR = i18n.global.t('messages.no student load') + errorPrepend();
  static LOAD_MULTIPLE_ERROR = i18n.global.t('messages.no students load') + errorPrepend();
  static DELETE_ERROR = i18n.global.t('messages.error del student') + errorPrepend();
  static DELETE_MULTIPLE_ERROR = i18n.global.t('messages.error del students') + errorPrepend();
  static UPDATE_ERROR = i18n.global.t('messages.no student change') + errorPrepend();
  static UPDATE_CLASS_ERROR = i18n.global.t('messages.no students class change') + errorPrepend();
  static UPDATE_MULTIPLE_ERROR = i18n.global.t('messages.no students change') + errorPrepend();

  constructor (public id: number,
               public firstname: string,
               public lastname: string,
               public email: string,
               public classId?: number | null,
               public password?: string) {
    super(id, firstname, lastname, email, password);
  }

  static async getAll (): Promise<Student[]> {
    const { data } = await axios.get('api/student/all');
    return data;
  }

  static async getById (studentId: number): Promise<Student> {
    const { data } = await axios.get(`api/student/${studentId}`);
    return data;
  }

  static async update (student: Student): Promise<void> {
    const { data } = await axios.put('api/student', student);
    return data;
  }

  static async add (students: Student[]): Promise<void> {
    const { data } = await axios.post('api/student', students);
    return data;
  }

  static async delete (studentId: number): Promise<void> {
    const { data } = await axios.delete(`api/student/${studentId}`);
    return data;
  }

  static async updateClass (classId: number, studentId: number): Promise<void> {
    const { data } = await axios.put('api/student/class', { classId, studentId });
    return data;
  }
}
