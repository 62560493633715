export default {
  "messages": {
    "Anmeldung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung"])},
    "SZ Wordly registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SZ-Wordly Anmeldung"])},
    "Welcome back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen zurück"])},
    "or with local user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oder mit lokalem Benutzer"])},
    "SZ-Wordly - vocabulary trainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SZ-Wordly - Vokabeltrainer"])},
    "save email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail speichern"])},
    "E-mail / username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail / Benutzername"])},
    "Sign-Out from SZ Wordly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von SZ-Wordly abmelden"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolg!"])},
    "Successfully logged off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich abgemeldet!"])},
    "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "Sign in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "Administrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
    "Delete?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wirklich löschen?"])},
    "Delete book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soll das Buch wirklich gelöscht werden"])},
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menü"])},
    "source language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgangssprache:"])},
    "target language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zielsprache:"])},
    "Publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlag"])},
    "Startseite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startseite"])},
    "book templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Büchervorlagen"])},
    "school administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schulverwaltung"])},
    "audio management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audioverwaltung"])},
    "Einstellungen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
    "books management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bücherverwaltung"])},
    "Available Books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbare Bücher"])},
    "Create new book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Buch erstellen"])},
    "Surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
    "Extinguish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
    "Change Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ändern"])},
    "old password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altes Passwort"])},
    "New Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort "])},
    "Repeat new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort wiederholen"])},
    "password length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwortlänge"])},
    "Show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeigen"])},
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "first name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
    "Create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
    "search term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchbegriff"])},
    "Create new school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Schule erstellen"])},
    "Create school button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schule erstellen Button"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "create school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schule erstellen "])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "Add new superlearning music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Superlearningmusik hinzufügen"])},
    "music name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musikname"])},
    "name of the new music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der neuen Musik"])},
    "Add to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
    "Existing learning music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorhandene Lernmusik"])},
    "Access failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlgeschalgener Zugriff"])},
    "mistake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler!"])},
    "You do not have access to this resource!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast keinen Zugriff auf diese Ressource!"])},
    "Sign out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
    "Schüler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schüler"])},
    "your profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Profil"])},
    "voice output": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprachausgabe"])},
    "superlearning music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superlearningmusik"])},
    "Voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimme"])},
    "masculine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["männlich"])},
    "Feminine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["weiblich"])},
    "Number of vocabulary per learning unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vokabelanzahl pro Lerneinheit"])},
    "my courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Kurse"])},
    "There are no courses available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es sind keine Kurse vorhanden"])},
    "Sign out of school account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vom Schulkonto abmelden"])},
    "start training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training starten"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
    "Select vocabulary:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vokabel auswählen:"])},
    "three choices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie die richtige Übersetzung des Wortes aus den drei vorgegebenen Auswahlmöglichkeiten aus."])},
    "Form vocabulary pairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilden Sie Vokabelpaare, indem Sie die Ausgangssprache und Zielsprache des Wortes anklicken."])},
    "training completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training abgeschlossen!"])},
    "You have completed x of x": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben x von x Übungen ohne Fehler richtig abgeschlossen…"])},
    "Repeat Lecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Wiederholen "])},
    "Back to the lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Lektion"])},
    "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortschritt"])},
    "No picture available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Bild vorhanden"])},
    "form vocabulary pairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vokabelpaare bilden"])},
    "Choose word based image displayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie anhand des angezeigten Bildes und des gehörten Audios das richtige Wort aus. Versuchen Sie außerdem das gehörte Wort nachzusprechen."])},
    "See, hear & select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sehen, hören & Vokabel auswählen:"])},
    "Write the word that can be seen in the picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schreiben Sie das Wort, welches am Bild zu sehen und im Audio zu hören ist, in das vorgegebene Textfeld. Versuchen Sie außerdem das gehörte Wort nachzusprechen."])},
    "License:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizenz: "])},
    "Choose vocabulary based on image displayed.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Wählen Sie anhand des angezeigten Bildes das richtige Vokabel aus."])},
    "See picture & select vocabulary:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild sehen & Vokabel auswählen:"])},
    "Check answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antworten prüfen"])},
    "write vocabulary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vokabel schreiben"])},
    "Write translation text box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schreiben Sie die richtige Übersetzung des Wortes in das vorgegebene Textfeld."])},
    "Which units learn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welche Units möchtest du lernen?"])},
    "Lehrer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lehrer"])},
    "Create course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs erstellen"])},
    "course name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursname: "])},
    "Description:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung:"])},
    "Source language:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgangssprache:"])},
    "Add Lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lektion hinzufügen"])},
    "Create Lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lektion erstellen"])},
    "Vocabulary list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vokabelliste*"])},
    "*optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*optional"])},
    "Apply template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage anwenden"])},
    "insert lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lektionen einfügen"])},
    "lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lektionen"])},
    "course members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursmitglieder"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiken"])},
    "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagen"])},
    "No course members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es sind keine Kursmitglieder vorhanden"])},
    "Choose a file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei auswählen"])},
    "Upload vocabulary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vokabeln hochladen"])},
    "Delete Courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs(e) löschen"])},
    "Which courses delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welche Kurse möchtest du löschen?"])},
    "confirm selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswahl bestätigen!"])},
    "delete lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lektionen löschen"])},
    "Which lessons delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welche Lektionen möchtest du löschen?"])},
    "select class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klasse auswählen"])},
    "select students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schüler auswählen"])},
    "Add student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schüler hinzufügen"])},
    "course member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursmitglied"])},
    "Organisator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisator"])},
    "change class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klasse ändern"])},
    "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schüler"])},
    "New name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Name"])},
    "change name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namen ändern"])},
    "NoLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NoLogin"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team"])},
    "Common": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common"])},
    "add a picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild hinzufügen"])},
    "login with school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Schulkonto anmelden"])},
    "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error 404: Seite nicht gefunden"])},
    "page not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Seite konnte nicht gefunden werden"])},
    "back to home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Startseite"])},
    "back to login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zum Login"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])},
    "no learn music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lernmusik wird von Ihrem Browser icht unterstützt"])},
    "delete music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musik löschen"])},
    "new class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Klasse erstellen"])},
    "delete class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klasse löschen"])},
    "name of new class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der neuen Klasse"])},
    "remove student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schüler aus der Klasse entfernen"])},
    "no students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt keine Schüler in dieser Klasse"])},
    "show warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warnungen zeigen"])},
    "export new passwords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Passwörter exportieren"])},
    "migrate user ad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer und Gruppen von Microsoft Active Directory übernehmen"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starte den"])},
    "training mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trainingsmodus"])},
    "no vocab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es sind keine Vokablen vorhanden"])},
    "enable editing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeitung aktivieren"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwerfen"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "no book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es sind keine Bücher vorhanden!"])},
    "save changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen speichern"])},
    "csv files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV Datei(en)"])},
    "name of lecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Lektion"])},
    "generate new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort generieren"])},
    "reset values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werte zurücksezen"])},
    "delete user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer löschen"])},
    "no desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Kurs hat noch keine Beschreibung"])},
    "del lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lektion löschen"])},
    "hide lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lektion ausblenden"])},
    "show lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lektion einblenden"])},
    "edit lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lektion bearbeiten"])},
    "no lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es sind keine Lektionen vorhanden"])},
    "discard changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen verwerfen"])},
    "pic desc and license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildbeschreibung/Lizenz"])},
    "enter license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füge die Lizenz im Feld ein:"])},
    "add student to class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schüler hinzufügen zur Klasse"])},
    "Class of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klasse von "])},
    "class name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klassenname"])},
    "See, hear & write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sehen, hören & Vokalbel schreiben"])},
    "See pic & write vocab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild sehen & Vokabel schreiben: "])},
    "write word from picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schreiben Sie das Wort, welches am Bild zu sehen ist, in das vorgegebene Textfeld."])},
    "check answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antwort prüfen"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktiviert"])},
    "save settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen speichern"])},
    "no students course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es befinden sich keine Schüler in diesem Kurs!"])},
    "no statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es sind keine Statistiken für diesen Kurs vorhanden!"])},
    "no templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es sind keine Vorlagen für diese Sprachkombination vorhanden!"])},
    "change course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs bearbeiten"])},
    "del user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer entfernen"])},
    "add member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglied hinzufügen"])},
    "add members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitlgieder hinzufügen"])},
    "add whole class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine ganze Klasse kann hinzugefügt werden, indem kein Schüler ausgewählt wird!"])},
    "information1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Ziel der vorliegenden Diplomarbeit ist, eine Online-Lernplattform zu entwickeln, die ein effizienteres Lernen für Schüler ermöglicht und dabei den organisatorischen Aufwand für Lehrer so gering wie möglich hält. Die herkömmlichste Variante des Vokabellernens ist die Verwendung eines Vokabelhefts. Diese Methode bereitet dem Lehrer nur wenig Aufwand, jedoch gestaltet sich das Lernen sehr langwierig und ineffizient."])},
    "information2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Der größte Vorteil eines Onlinetrainers gegenüber dem Vokabelheft ist die Art wie gelernt wird. Die Plattform erlaubt den Einsatz von verschiedensten Lernmethoden unter Berücksichtigung der unterschiedlichen Lerntypen. Die Schüler können jederzeit die Vokabeln aus ihren Schulbüchern lernen, denn die Lehrkraft stellt den Schülern die Vokabeln in vorgefertigten Listen zur Verfügung und kann dauerhaft den Lernfortschritt der Schüler verfolgen."])},
    "information3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Hilfe eines Lernalgorithmus, der im Hintergrund berechnet, welche Vokabeln ausgewählt werden, wird ein individuelles Training ermöglicht, das gezielt auf den Benutzer abgestimmt ist. Der Algorithmus priorisiert Vokabeln, die der Benutzer noch nicht so gut beherrscht und prüft diese dementsprechend öfter ab."])},
    "information4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Vor allem auditive Lerntypen können durch Musikhören während des Lernens stark profitieren. Das Gehirn lernt viel effektiver, wenn es während dem Lernen mit Geräuschen stimuliert wird, das trifft allerdings ausschließlich bei einer gleichmäßigen und ausgewogenen Geräuschkulisse zu. So wird ein Arbeitsklima geschaffen, welches die Konzentration fördert, ohne für Ablenkung zu sorgen. Darum bietet SZ-Wordly auch Superlearningmusik an. Es werden vier Tracks bereitgestellt:"])},
    "our idea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsere Idee"])},
    "no superlearning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist keine Superlearningmusik vorhanden."])},
    "no cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Cover"])},
    "book name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Buches"])},
    "publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Verlages"])},
    "add csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV Datei hochladen "])},
    "Benutzerverwaltung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerverwaltung"])},
    "Klassenverwaltung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klassenverwaltung"])},
    "Kursübersicht": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursübersicht"])},
    "Meine Kurse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Kurse"])},
    "Büchervorlagen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Büchervorlagen"])},
    "Schulverwaltung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schulverwaltung"])},
    "Audioverwaltung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audioverwaltung"])},
    "Bücherverwaltung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bücherverwaltung"])},
    "Kursdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursdetails"])},
    "Lektionsdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lektionsdetails"])},
    "Lernen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lernen"])},
    "Buchdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchdetails"])},
    "Projektleiter, Frontend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektleiter, Frontend"])},
    "Frontend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frontend"])},
    "Backend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend"])},
    "music1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrumental Barock - Track"])},
    "music2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrumental Klassik - Track"])},
    "music3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ambient Musik - Track"])},
    "music4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naturgeräusche / Umgebungsgeräusche - Track"])},
    "del music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soll die Musik wirklich gelöscht werden?"])},
    "no return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Aktion kann nicht umgekehrt werden!"])},
    "no changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine Passwörter geändert!"])},
    "edit school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schule bearbeiten"])},
    "del student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schüler aus der Klasse entfernen"])},
    "del school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Schule wirklich löschen?"])},
    "school info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schulinformationen"])},
    "you have": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben"])},
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])},
    "exercises solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übungen ohne Fehler richtig abgeschlossen..."])},
    "wrong translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das stimmt leider nicht, die richtige Übersetzung von"])},
    "is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist"])},
    "password matching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort muss übereinstimmen!"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information!"])},
    "confirm del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, löschen"])},
    "session timeout text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Sitzung ist abgelaufen, Sie müssen sich erneut anmelden!"])},
    "session timeout title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzung abgelaufen"])},
    "not log in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind nicht angemeldet!"])},
    "log in missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung fehlt"])},
    "Seite nicht gefunden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite nicht gefunden"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und"])},
    "no book change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Buch konnte nicht geändert werden. "])},
    "no book del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Buch konnte nicht gelöscht werden. "])},
    "no book create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buch konnte nicht erstellt werden. "])},
    "no book del full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bücher konnte nicht vollständig gelöscht werden. "])},
    "no book load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Buch konnte nicht geladen werden. "])},
    "no books load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bücher konnten nicht geladen werden. "])},
    "no class add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Klasse konnte nicht hinzugefügt werden. "])},
    "no classes add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Klassen konnten nicht hinzugefügt werden. "])},
    "no class load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Klasse konnte nicht geladen werden . "])},
    "no classes load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Klassen konnten nicht geladen werden. "])},
    "error class load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gab einen Fehler beim Entfernen der ausgewählten Klasse. "])},
    "error classes load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gab einen Fehler beim Entfernen der ausgewählten Klassen. "])},
    "no class change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Klasse konnte nicht geändert werden. "])},
    "fill all fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte fülle alle Felder aus!"])},
    "no data load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Daten konnten nicht geladen werden. "])},
    "file to big": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die hochgeladene(n) Datei(en) sind zu groß! Maximal Größe 40 MiB!"])},
    "no course load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kurs konnte nicht geladen werden. "])},
    "no course add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kurs konnte nicht hinzugefügt werden. "])},
    "course error del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gab einen Fehler beim Löschen der ausgewählten Kurse. "])},
    "course error change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gab einen Fehler beim Ändern des Kurses. "])},
    "try again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte versuche es erneut!"])},
    "no lesson add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Lektion konnte nicht hinzugefügt werden. "])},
    "no lessons load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Lektionen konnten nicht geladen werden. "])},
    "no lesson load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Lektion konnte nicht geladen werden . "])},
    "error del lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gab einen Fehler beim Löschen der ausgewählten Lektionen. "])},
    "no lesson change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Lektion konnte nicht geändert werden. "])},
    "no statistic load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Statistiken konnten nicht geladen werden. "])},
    "template add course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage wurde zum Kurs hinzugefügt"])},
    "error add template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Hinzufügen der Vorlage. "])},
    "no organizer add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Organisator konnte nicht hinzugefügt werden. "])},
    "no organizers add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Organisatoren konnten nicht hinzugefügt werden. "])},
    "no organizer load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Organisator konnte nicht geladen werden . "])},
    "no organizers load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Organisatoren konnten nicht geladen werden. "])},
    "error del organizer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gab einen Fehler beim Entfernen des ausgewählten Organisator. "])},
    "error del organizers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gab einen Fehler beim Entfernen der ausgewählten Organisatoren. "])},
    "no organizer change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Organisator konnte nicht geändert werden. "])},
    "no organizers change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Organisatoren konnten nicht geändert werden. "])},
    "no school add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Schule konnte nicht hinzugefügt werden. "])},
    "no school load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Schule konnte nicht geladen werden . "])},
    "no schools load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Schulen konnten nicht geladen werden. "])},
    "error del school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gab einen Fehler beim Entfernen der ausgewählten Schule. "])},
    "error school change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Schule konnte nicht geändert werden. "])},
    "request a minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Anfrage kann bis zu einer Minute dauern, bitte habe Geduld!"])},
    "settings saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Einstellungen wurden gespeichert."])},
    "error save settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Speichern der Einstellungen. "])},
    "error load settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Laden der Einstellungen. "])},
    "error load statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Statistiken konnten nicht geladen werden. "])},
    "no student add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Schüler konnte nicht hinzugefügt werden. "])},
    "no students add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Schüler konnten nicht hinzugefügt werden. "])},
    "no student load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Schüler konnte nicht geladen werden . "])},
    "no students load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Schüler konnten nicht geladen werden. "])},
    "error del student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gab einen Fehler beim Entfernen des ausgewählten Schülers. "])},
    "error del students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gab einen Fehler beim Entfernen der ausgewählten Schüler. "])},
    "no student change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Schüler konnte nicht geändert werden. "])},
    "no students class change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Klasse des Schülers konnte nicht geändert werden. "])},
    "no students change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Schüler konnten nicht geändert werden. "])},
    "no start training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trainingsmodus konnte nicht gestartet werden. "])},
    "no del audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Audio konnte nicht gelöscht werden. "])},
    "no upload audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Audio konnte nicht hochgeladen werden. "])},
    "no load audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Audiodateien konnten nicht geladen werden. "])},
    "no teacher add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Lehrer konnte nicht hinzugefügt werden. "])},
    "no teachers add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Lehrer konnten nicht hinzugefügt werden. "])},
    "no teacher load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Lehrer konnte nicht geladen werden . "])},
    "no teachers load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Lehrer konnten nicht geladen werden. "])},
    "error del teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gab einen Fehler beim Entfernen des ausgewählten Lehrers. "])},
    "error del teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gab einen Fehler beim Entfernen der ausgewählten Lehrer. "])},
    "no change teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Lehrer konnte nicht geändert werden. "])},
    "no change teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Lehrer konnte nicht geändert werden. "])},
    "no add vocab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Vokabeln konnten nicht zur Lektion hinzugefügt werden. "])},
    "no upload vocab image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Vokabel-Bild konnte nicht hochgeladen werden. "])},
    "fail signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden fehlgeschlagen. "])},
    "invalid user/pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername oder Passwort falsch!"])},
    "error ms login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Microsoftlogin ist ein Fehler aufgetreten! "])},
    "no password change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort konnte nicht geändert werden! "])},
    "password changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wurde erfolgreich geändert! "])},
    "valid email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib eine gültige E-Mail-Adresse ein"])},
    "del user?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soll der Benutzer wirklich gelöscht werden?"])},
    "del lesson?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willst du die Lektion wirklich löschen?"])},
    "BuchDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchdetails"])},
    "create user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer erstellen"])}
  }
}