
import { defineComponent } from 'vue';
import { mixin as clickaway } from 'vue3-click-away';
export default defineComponent({
  mixins: [clickaway],
  data () {
    return {
      isVisible: false,
      focusedIndex: 0,
      germanySVG: require('@/assets/img/country_flags/flag_Deutsch.svg'),
      englishSVG: require('@/assets/img/country_flags/flag_English.svg'),
      frenchSVG: require('@/assets/img/country_flags/flag_French.svg'),
      spanishSVG: require('@/assets/img/country_flags/flag_Spanish.svg'),
      italianSVG: require('@/assets/img/country_flags/flag_Italian.svg'),
      ukraineSVG: require('@/assets/img/country_flags/flag_Ukraine.svg'),
      currentSVG: require('@/assets/img/country_flags/flag_' + this.$i18n.locale + '.svg')
    };
  },
  methods: {
    toggleVisibility () {
      this.isVisible = !this.isVisible;
    },
    setLocale (locale:string) {
      this.$i18n.locale = locale;
      this.updateFlag;
      this.hideDropdown();
    },
    hideDropdown () {
      this.isVisible = false;
      this.focusedIndex = 0;
    },

    startArrowKeys () {
      if (this.isVisible) {
        // this.$refs.account.focus()
        (this.$refs.dropdown as any).children[0].children[0].focus();
      }
    },
    focusPrevious (isArrowKey:boolean) {
      this.focusedIndex = this.focusedIndex - 1;
      if (isArrowKey) {
        this.focusItem();
      }
    },
    focusNext (isArrowKey:boolean) {
      this.focusedIndex = this.focusedIndex + 1;
      if (isArrowKey) {
        this.focusItem();
      }
    },
    focusItem () {
      (this.$refs.dropdown as any).children[this.focusedIndex].children[0].focus();
    },
    setSVG (flag:any) {
      this.currentSVG = flag;
    }
  },
  computed: {
    updateFlag () {
      var flag = require('@/assets/img/country_flags/flag_' + this.$i18n.locale + '.svg');
      this.setSVG(flag);
      return 0;
    }
  }
});
