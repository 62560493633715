import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-724a6f0c")
const _hoisted_1 = {
  id: "sidebar",
  class: "sidebar border-right py-1"
}
const _hoisted_2 = { class: "text-gray-400 text-uppercase px-3 px-lg-4 py-4 font-weight-bold small headings-font-family" }
const _hoisted_3 = { class: "sidebar-menu list-unstyled" }
const _hoisted_4 = { class: "pl-xl-1" }
const _hoisted_5 = ["data-target"]
const _hoisted_6 = { class: "pl-xl-1" }
const _hoisted_7 = ["id"]
const _hoisted_8 = { class: "sidebar-menu list-unstyled border-left border-primary border-thick" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("messages.menu")), 1),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sideNavItems, (sideNavItem) => {
        return (_openBlock(), _createElementBlock("li", {
          class: "sidebar-list-item",
          key: sideNavItem.link
        }, [
          (!sideNavItem.children)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: sideNavItem.link,
                class: "sidebar-link text-muted"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("i", {
                    class: _normalizeClass(['fas', sideNavItem.icon ,'mr-3', 'text-gray', 'col-1'])
                  }, null, 2),
                  _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("messages."+sideNavItem.text)), 1)
                ]),
                _: 2
              }, 1032, ["to"]))
            : (_openBlock(), _createElementBlock("a", {
                key: 1,
                href: "",
                class: "sidebar-link text-muted",
                "data-toggle": "collapse",
                "data-target": `#${sideNavItem.text.replace(/\s/g, '')}`,
                "aria-expanded": "false"
              }, [
                _createElementVNode("i", {
                  class: _normalizeClass(['fas', sideNavItem.icon ,'mr-3', 'text-gray', 'col-1'])
                }, null, 2),
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("messages."+sideNavItem.text)), 1)
              ], 8, _hoisted_5)),
          _createElementVNode("div", {
            id: sideNavItem.text.replace(/\s/g, ''),
            class: "collapse"
          }, [
            _createElementVNode("ul", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sideNavItem.children, (child) => {
                return (_openBlock(), _createBlock(_component_router_link, {
                  to: child.link,
                  class: "dropdown-item",
                  key: child.link
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(child.text), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]))
              }), 128))
            ])
          ], 8, _hoisted_7)
        ]))
      }), 128))
    ])
  ]))
}