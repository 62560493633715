import axios from 'axios';
import { Student } from '@/models/Student';
import { errorPrepend } from '@/models/index';
import i18n from '@/i18n';

export class Class {
  static ADD_ERROR = i18n.global.t('messages.no class add') + errorPrepend();
  static ADD_MULTIPLE_ERROR = i18n.global.t('messages.no classes add') + errorPrepend();
  static LOAD_ERROR = i18n.global.t('messages.no class load') + errorPrepend();
  static LOAD_MULTIPLE_ERROR = i18n.global.t('messages.no classes load') + errorPrepend();
  static DELETE_ERROR = i18n.global.t('messages.error class load') + errorPrepend();
  static DELETE_MULTIPLE_ERROR = i18n.global.t('messages.error classes load') + errorPrepend();
  static UPDATE_ERROR = i18n.global.t('messages.no class change') + errorPrepend();
  static UPDATE_MULTIPLE_ERROR = i18n.global.t('messages.no class change') + errorPrepend();

  constructor (
    public id: number,
    public name: string,
    public schoolId: number,
    public students: Student[]
  ) {
  }

  static async getAll (): Promise<Class[]> {
    const { data } = await axios.get('api/class/all');
    return data;
  }

  static async update (classVal: Class): Promise<void> {
    const { data } = await axios.put('api/class', classVal);
    return data.students;
  }

  static async getAllStudents (classId: number): Promise<Student[]> {
    const { data } = await axios.get(`api/class/${classId}`);
    return data.students;
  }

  static async create (classVal: Class): Promise<void> {
    const { data } = await axios.post('api/class', classVal);
    return data;
  }

  static async delete (classId: number): Promise<void> {
    const { data } = await axios.delete(`api/class/${classId}`);
    return data;
  }

  static async getById (classId: number): Promise<Class> {
    const { data } = await axios.get(`api/class/${classId}`);
    return data;
  }

  static async updateStudents (classId: number, students: Student[]): Promise<void> {
    const { data } = await axios.put('api/class/students', {
      id: classId,
      studentIds: students.map(s => s.id)
    });
    return data;
  }
}
