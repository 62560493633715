import axios from 'axios';
import { EditableUser } from '@/models/User';
import { errorPrepend } from '@/models/index';
import i18n from '@/i18n';

export class Teacher extends EditableUser {
  static ADD_ERROR = i18n.global.t('messages.no teacher add') + errorPrepend();
  static ADD_MULTIPLE_ERROR = i18n.global.t('messages.no teachers add') + errorPrepend();
  static LOAD_ERROR = i18n.global.t('messages.no teacher load') + errorPrepend();
  static LOAD_MULTIPLE_ERROR = i18n.global.t('messages.no teachers load') + errorPrepend();
  static DELETE_ERROR = i18n.global.t('messages.error del teacher') + errorPrepend();
  static DELETE_MULTIPLE_ERROR = i18n.global.t('messages.error del teachers') + errorPrepend();
  static UPDATE_ERROR = i18n.global.t('messages.no change teacher') + errorPrepend();
  static UPDATE_MULTIPLE_ERROR = i18n.global.t('messages.no change teachers') + errorPrepend();

  static async getAll (): Promise<Teacher[]> {
    const { data } = await axios.get('api/teacher/all');
    return data;
  }

  static async getById (teacherId: number): Promise<Teacher> {
    const { data } = await axios.get(`api/teacher/${teacherId}`);
    return data;
  }

  static async update (teacher: Teacher): Promise<void> {
    const { data } = await axios.put('api/teacher', teacher);
    return data;
  }

  static async add (teachers: Teacher[]): Promise<void> {
    const { data } = await axios.post('api/teacher', teachers);
    return data;
  }

  static async delete (teacherId: number): Promise<void> {
    const { data } = await axios.delete(`api/teacher/${teacherId}`);
    return data;
  }
}
