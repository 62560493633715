
import { defineComponent } from 'vue';
import TheFooter from '@/components/common/layout/TheFooter.vue';
import TheHeader from '@/components/common/layout/TheHeader.vue';
import TheSideNav from '@/components/common/layout/TheSideNav.vue';
import TheBottomMenu from '@/components/common/layout/TheBottomMenu.vue';
import { mapState } from 'vuex';
import { showError, showSessionTimeout } from '@/sweetalert2/templates.ts';
import { User, errorPrepend } from '@/models';
import axios, { AxiosError } from 'axios';
import { msalInstance } from '@/msal/authConfig.ts';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { RouteLocationNormalized, RouteLocationRaw } from 'vue-router';

export default defineComponent({

  components: { TheBottomMenu, TheSideNav, TheHeader, TheFooter },
  created () {
    this.setAxiosInterceptor();
    this.addMsalCallback();
  },
  methods: {
    setAxiosInterceptor () {
      axios.interceptors.response.use((res) => res, (error: AxiosError) => {
        if (error.response?.status === 401) {
          localStorage.setItem('hasSession', String(false));
          this.$store.dispatch('deleteData');
          const popup = async () => {
            await showSessionTimeout();
            let route: RouteLocationRaw;
            const fullPath = this.$route.fullPath;
            if (fullPath !== '/') {
              route = {
                path: '/login/',
                query: { redirect: fullPath }
              };
            } else {
              route = { path: '/login/' };
            }
            await this.$router.push(route);
          };
          setTimeout(() => popup(), 10);
        }
        return Promise.reject(error);
      });
    },
    addMsalCallback () {
      msalInstance.addEventCallback(async (event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
          const payload = event.payload as AuthenticationResult;
          const account = payload.account;
          msalInstance.setActiveAccount(account);
          try {
            const data = await User.loginAd(payload.accessToken);
            await this.$store.dispatch('createSessionFromJwt', data.usertype);
            await this.$router.replace(User.getLandingPage(this.user.type));
          } catch (e) {
            await showError(this.$t("messages.error ms login") + errorPrepend());
          }
        }
      });
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (newVal: RouteLocationNormalized) {
        document.title = newVal.name?.toString() ?? 'SZ-Wordly';
      }
    }
  },
  computed: {
    ...mapState({
      sideNavVisible: 'sideNavVisible',
      loggedIn: 'loggedIn',
      user: 'user'
    }),
    fullPath () {
      return this.$route.fullPath;
    }
  }
});
