import { LanguageShort } from '@/models/Language';
import { errorPrepend, Translation } from '@/models/index';
import axios from 'axios';
import toFormData from '@/models/FormData';
import { Statistics } from '@/models/Statistics';
import i18n from '@/i18n';

export class Lesson {
  static ADD_ERROR = i18n.global.t('messages.no lesson add') + errorPrepend();
  static LOAD_MULTIPLE_ERROR = i18n.global.t('messages.no lessons load') + errorPrepend();
  static LOAD_ERROR = i18n.global.t('messages.no lesson load') + errorPrepend();
  static DELETE_MULTIPLE_ERROR = i18n.global.t('messages.error del lesson') + errorPrepend();
  static UPDATE_ERROR = i18n.global.t('messages.no lesson change') + errorPrepend();
  static STATS_ERROR = i18n.global.t('messages.no statistic load') + errorPrepend();
  static TEMPLATE_SUC = i18n.global.t('messages.template add course');
  static TEMPLATE_ERROR = i18n.global.t('messages.error add template') + errorPrepend();

  constructor (
    public id: number,
    public name: string,
    public languageFrom: LanguageShort,
    public languageTo: LanguageShort,
    public visibility?: boolean
  ) {
  }

  static async getById (lessonId: number): Promise<Lesson> {
    const { data } = await axios.get(`api/lesson/${lessonId}`);
    return data;
  }

  static async getFromCourse (courseId: number): Promise<Lesson[]> {
    const { data } = await axios.get<Lesson[]>(`api/course/${courseId}/lesson/all`);
    return data;
  }

  static async update (lesson: Lesson): Promise<void> {
    const { data } = await axios.put('api/lesson', {
      lessonId: lesson.id,
      name: lesson.name,
      languageFrom: lesson.languageFrom,
      languageTo: lesson.languageTo,
      visibility: true
    });
    return data;
  }

  static async changeLessonVisibility (lesson: Lesson): Promise<void> {
    const { data } = await axios.put('api/lesson', {
      lessonId: lesson.id,
      name: lesson.name,
      visibility: lesson.visibility
    });
    return data;
  }

  static async downloadAudios (lessonId: number): Promise<void> {
    const { data } = await axios.put('api/lesson/translations/audio', { lessonId });
    return data;
  }

  static async delete (lessonId: number): Promise<void> {
    const { data } = await axios.delete(`api/lesson/${lessonId}`);
    return data;
  }

  static async insertLessonTemplate (courseId: number, lessons: Array<number>): Promise<void> {
    const { data } = await axios.post('api/course/lesson', {
      courseId: courseId,
      booklessonIds: lessons
    });
    return data;
  }

  static async getStatistics (courseId: number): Promise<Statistics> {
    const { data } = await axios.get(`/api/student/course/${courseId}/stats`);
    return data;
  }

  static async uploadImage (translation: Translation, lessonId: number, file: File, license: string): Promise<{imageUrl: string}> {
    const formData = toFormData({ vocabFrom: translation.fromId, vocabTo: translation.toId, lessonId: lessonId, image: file, imageDescription: license });
    const { data } = await axios.post('api/lesson/translation/image', formData);
    return data;
  }
}
